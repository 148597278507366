import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Recaptcha } from '../MainLayout/Recaptcha'
import loadingGif from "../../images/gifs/addLoadingGif.gif";
import { useGetTransferByIdQuery, useUpdateTransferMutation } from '../../Api/TransferApi';
import { IUser } from '../../interfaces/IUser';
import { SD_Roles, SD_TransferStatus } from '../../Utility/StaticDetails';
import toastNotify from '../../Helper/ToastNotify';
import { IMoneyTransferUpdateAdmin } from '../../interfaces/IMoneyTransferUpdateAdmin';
import IApiResponse from '../../interfaces/IApiResponse';
import { ITransferReq } from '../../interfaces/ITransferReq';

interface Props {
  userInfo: IUser,
  transferRequestId: number,
  setTransferRequestId: React.Dispatch<React.SetStateAction<number>>
  verified: boolean
  setVerified: React.Dispatch<React.SetStateAction<boolean>>
  captchaRef: React.RefObject<ReCAPTCHA>
}

export const TransferRequestDetail = ({ userInfo, transferRequestId, setTransferRequestId, verified, setVerified, captchaRef }: Props) => {


  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useGetTransferByIdQuery(transferRequestId);

  const transferFromDb: ITransferReq = data?.result;

  const [transferDTO, setTransferDTO] = useState<IMoneyTransferUpdateAdmin>(() => {
    return {
      transferStatus: '',
      adminTransactionNo: '',
      adminReceipt: null,
      adminNote: '',
    }
  });


  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement & HTMLTextAreaElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setTransferDTO(() => {
      return { ...transferDTO, [name]: value }
    });
  }

  let icon = <i className="bi bi-clock-history" style={{ color: 'var(--Orange-6)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;

  if (transferDTO?.transferStatus === 'pending') {
    icon = <i className="bi bi-clock-history" style={{ color: 'var(--Orange-6)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferDTO?.transferStatus === 'approved') {
    icon = <i className="bi bi-hand-thumbs-up" style={{ color: 'var(--Blue-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferDTO?.transferStatus === 'completed') {
    icon = <i className="bi bi-check-circle" style={{ color: 'var(--Green-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferDTO?.transferStatus === 'rejected') {
    icon = <i className="bi bi-x-circle" style={{ color: 'var(--Red-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferFromDb?.transferStatus === 'pending') {
    icon = <i className="bi bi-clock-history" style={{ color: 'var(--Orange-6)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferFromDb?.transferStatus === 'approved') {
    icon = <i className="bi bi-hand-thumbs-up" style={{ color: 'var(--Blue-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferFromDb?.transferStatus === 'completed') {
    icon = <i className="bi bi-check-circle" style={{ color: 'var(--Green-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  } else if (transferFromDb?.transferStatus === 'rejected') {
    icon = <i className="bi bi-x-circle" style={{ color: 'var(--Red-7)', fontSize: '22px', position: 'absolute', right: '0.3rem', top: '0.5rem' }}></i>;
  }


  // ----------------------- handling load Receipt here ----------------------- //
  const [adminReceiptUpload, setAdminReceiptUpload] = useState<any>();
  const [adminReceiptPreview, setAdminReceiptPreview] = useState<string>(() => { return "" });

  const handleReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImgTpyeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setAdminReceiptUpload("");
        toastNotify("File must be less than 1 MB", "error");
        return;
      }

      else if (isImgTpyeValid.length === 0) {
        setAdminReceiptUpload("");
        toastNotify("File must be in jpeg, jpg, or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setAdminReceiptUpload(file);

      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;
        setAdminReceiptPreview(imgUrl)
      }
    }
  };

  const [updateTransfer] = useUpdateTransferMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);


    // appends data from the form and local variables to the FromData
    const formData = new FormData();

    if (!transferFromDb.id) {
      toastNotify("No Request Found, try again.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("id", transferFromDb.id.toString());
    }

    if (!transferFromDb.receiver.id) {
      toastNotify("No Receiver Found, try again.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("receiverId", transferFromDb.receiver.id.toString());
    }

    if (!transferFromDb.amount) {
      toastNotify("Please enter amount.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("amount", transferFromDb.amount.toString());
    }

    if (!transferFromDb.currency.id) {
      toastNotify("Please select a currency.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("currencyId", transferFromDb.currency.id.toString());
    }

    if (!transferFromDb.clientTransactionNo) {
      toastNotify("Please enter client Transaction No.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("clientTransactionNo", transferFromDb.clientTransactionNo.toString());
    }

    formData.append("clientReceipt", transferFromDb.clientReceipt ? transferFromDb.clientReceipt.toString() : '');
    formData.append("clientNote", transferFromDb.clientNote ? transferFromDb.clientNote.toString() : '');

    formData.append("transferStatus", transferDTO.transferStatus === '' ? transferFromDb.transferStatus : transferDTO.transferStatus);

    formData.append("adminTransactionNo", transferDTO.adminTransactionNo === '' ? transferFromDb.adminTransactionNo : transferDTO.adminTransactionNo);

    if (adminReceiptUpload) {
      formData.append("adminReceipt", adminReceiptUpload);
    }
    formData.append("adminNote", transferDTO.adminNote === '' ? transferFromDb.adminNote : transferDTO.adminNote);





    // submits the data for CategoryApi
    const response: IApiResponse = await updateTransfer({ data: formData, id: transferFromDb.id });
    if (response.data?.isSuccess) {

      setLoading(false);
      toastNotify("Request submited successfully!");

      setTransferRequestId(0);

      if (captchaRef.current !== null) {
        captchaRef.current.reset();
      }
      setVerified(false);


    } else {
      toastNotify(response.error.data.errorMessages[0], "error");
      // console.log(response);
    }
    setLoading(false);
  }



  if (isLoading) {
    return <h1>Data is loading...</h1>
  };


  return (
    <form method="put" className='transfer-form' encType='multipart/form-data' onSubmit={handleSubmit} >
      <h3 className="list-title" style={{ marginBottom: "1rem", borderBottom:'1px solid var(--Gray-6)' }}>Money transfer request details</h3>
      <div style={{ width: '100%' }}>

        {/* transfer status */}
        <div style={{ boxShadow: '0 0 4px var(--Gray-7)' }}>

          <div className='text-box-typ' style={{ border: '0', position: 'relative' }}>

            <p className='lbl-typ' > Transfer Status: </p>
            {(() => {
              if (transferFromDb?.transferStatus === 'pending') {
                return (
                  <>
                    <h3 style={{ color: 'var(--Orange-6)' }} >
                      {transferFromDb?.transferStatus.toUpperCase()}
                    </h3>
                    <i className="bi bi-clock-history" style={{ color: 'var(--Orange-6)', fontSize: '22px' }}></i>
                  </>
                )
              } else if (transferFromDb?.transferStatus === 'approved') {
                return (
                  <>
                    <h3 style={{ color: 'var(--Blue-7)' }} >
                      {transferFromDb?.transferStatus.toUpperCase()}
                    </h3>
                    <i className="bi bi-hand-thumbs-up" style={{ color: 'var(--Blue-7)', fontSize: '22px' }}></i>
                  </>
                )
              } else if (transferFromDb?.transferStatus === 'completed') {
                return (
                  <>
                    <h3 style={{ color: 'var(--Green-7)' }} >
                      {transferFromDb?.transferStatus.toUpperCase()}
                    </h3>
                    <i className="bi bi-check-circle" style={{ color: 'var(--Green-7)', fontSize: '22px' }}></i>
                  </>
                )
              } else if (transferFromDb?.transferStatus === 'rejected') {
                return (
                  <>
                    <h3 style={{ color: 'var(--Red-7)' }} >
                      {transferFromDb?.transferStatus.toUpperCase()}
                    </h3>
                    <i className="bi bi-x-circle" style={{ color: 'var(--Red-7)', fontSize: '22px' }}></i>
                  </>
                )
              }
            })()}
          </div>
        </div>

        <br />

        <h3>Sender's Information</h3>
        <div style={{ boxShadow: '0 0 4px var(--Gray-7)' }}>

          <div className='text-box-typ' style={{ border: '0' }}>
            <p className='lbl-typ' > Full Name: </p>
            <p>{transferFromDb?.receiver.appUser.fullName}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Phone Number: </p>
            <p>{transferFromDb?.receiver.appUser.phoneNumber}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Email: </p>
            <p>{transferFromDb?.receiver.appUser.email}</p>
          </div>

        </div>

        <br />

        <h3>Receiver's Information</h3>
        <div style={{ boxShadow: '0 0 4px var(--Gray-7)' }}>

          <div className='text-box-typ' style={{ border: '0' }}>
            <p className='lbl-typ' > Full Name: </p>
            <p>{transferFromDb?.receiver.fullName}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Phone Number: </p>
            <p>{transferFromDb?.receiver.phoneNumber}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Email: </p>
            <p>{transferFromDb?.receiver.email}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Bank: </p>
            <p>{transferFromDb?.receiver.bank.name}</p>
          </div>

          {transferFromDb.receiver.state.country.id != 97 ? (
            <>
              <div className='text-box-typ'>
                <p className='lbl-typ' > Bank Transit No: </p>
                <p>{transferFromDb?.receiver.transitNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > Bank Institution No: </p>
                <p>{transferFromDb?.receiver.bankInstitutionNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > Bank Account No: </p>
                <p>{transferFromDb?.receiver.bankAccountNo}</p>
              </div>
            </>
          ) : (
            <>
              <div className='text-box-typ'>
                <p className='lbl-typ' > Card No: </p>
                <p>{transferFromDb?.receiver.irCardNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > Bank Account No: </p>
                <p>{transferFromDb?.receiver.irAccountNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > IBAN: </p>
                <p>{transferFromDb?.receiver.iriban}</p>
              </div>

            </>
          )}



        </div>

        <br />

        <h3>Payment Information</h3>
        <div style={{ boxShadow: '0 0 4px var(--Gray-7)' }}>

          <div className='text-box-typ' style={{ position: 'relative', border: '0' }}>
            <p className='lbl-typ' > Amount: </p>
            <p
              style={{
                position: 'absolute',
                top: '0.75rem',
                right: '4.5rem',
                width: '7rem',
                height: '2.5rem',
                zIndex: '110',
                borderRadius: '0'
              }}
            >{transferFromDb?.currency.name} {transferFromDb?.amount}.00</p>
            <img
              src={transferFromDb?.currency.country?.imageUrl}
              alt="currency"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                width: '4rem',
                height: '2.5rem',
                zIndex: '110'
              }} />
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Client Transaction No: </p>
            <p>{transferFromDb?.clientTransactionNo}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Date: </p>
            <p>{transferFromDb?.createDate}</p>
          </div>

          <div className='text-box-typ'>
            <p className='lbl-typ' > Note: </p>
            <p>{transferFromDb?.clientNote}</p>
          </div>

          <div className='text-box-typ' style={{ height: '20rem', overflow: 'scroll' }}>
            <img
              src={transferFromDb?.clientReceipt}
              alt="currency"
              style={{ width: '100%', height: 'auto', margin: 'auto' }} />
          </div>
        </div>

        <br />

        {userInfo.role === SD_Roles.ADMIN ? (
          <>
            <h3>Admin Confirmation Info</h3>
            <div style={{ boxShadow: '0 0 4px var(--Gray-7)', marginTop: '0.1rem', paddingTop: '1rem' }}>
              <div className='input-typ-box'>
                <input
                  type="text"
                  name='adminTransactionNo'
                  className="input-typ"
                  // value={transferDTO.adminTransactionNo != '' ? transferDTO.adminTransactionNo : transferFromDb.adminTransactionNo}
                  value={transferDTO.adminTransactionNo ? transferDTO.adminTransactionNo : transferFromDb.adminTransactionNo}
                  onChange={handleChange}
                />

                <label
                  htmlFor="transactionNo"
                  className='lbl-input-typ'
                >
                  Confirmation No
                </label>

                <select
                  className="input-typ"
                  style={{
                    position: 'absolute',
                    width: '8rem',
                    top: '0',
                    right: '2rem',
                    borderRadius: '0'
                  }}
                  name="transferStatus"
                  required
                  value={!transferDTO.transferStatus ? transferFromDb.transferStatus : transferDTO.transferStatus}
                  onChange={handleChange}
                >
                  <option key={1} value={SD_TransferStatus.PENDING} >PENDING</option>
                  <option key={2} value={SD_TransferStatus.APPROVED} >APPROVED</option>
                  <option key={3} value={SD_TransferStatus.REJECTED} >REJECTED</option>
                  <option key={4} value={SD_TransferStatus.COMPLETED} >COMPLETED</option>
                </select>

                {icon}

              </div>

              <div className='input-typ-box'>
                <input
                  type="text"
                  name='adminNote'
                  className="input-typ"
                  // value={transferDTO.adminNote ? transferDTO.adminNote : transferFromDb.adminNote}
                  value={transferDTO.adminNote ? transferDTO.adminNote : transferFromDb.adminNote}
                  onChange={handleChange}
                />
                <label
                  htmlFor="AdminNote"
                  className='lbl-input-typ'
                >
                  Admin Note
                </label>
              </div>
              {/* <div className='text-box-typ' style={{ border: '0' }}>
                <p className='lbl-typ' > Full Name: </p>
                <p>{transferFromDb?.adminReceipt}</p>
              </div> */}

              <input
                type="file"
                className="input-typ"
                style={{
                  height: '1.5rem',
                  marginRight: 'auto',
                  marginBottom: '1rem',
                  padding: '0',
                }}
                onChange={handleReceiptChange}
              />

              <div className='text-box-typ' style={{ height: '20rem', overflow: 'scroll' }}>
                <img
                  src={!adminReceiptPreview ? transferFromDb.adminReceipt : adminReceiptPreview}
                  alt="Admin confirmation recepit"
                  style={{ width: '100%', height: 'auto', margin: 'auto' }}
                />
              </div>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Recaptcha setVerified={setVerified} captchaRef={captchaRef} />

              {loading ? (<img src={loadingGif} alt='loading gif' className="list-btn" style={{ marginRight: "0" }} />) : (
                <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem', width: '19rem' }}>
                  <button onClick={() => setTransferRequestId(0)} className="typ-item-btn btnDangerB"> Back </button>


                  {verified ? (<button className="typ-item-btn btnPrimary" style={{ flexGrow: "1" }}> Submit </button>
                  ) : (
                    <button className="typ-item-btn btnSecondary" disabled style={{ flexGrow: "1" }}> Submit </button>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          transferFromDb.transferStatus === 'completed' || transferFromDb.transferStatus === 'rejected' ? (
            <>
              <h3>Admin Confirmation Info</h3>
              <div style={{ boxShadow: '0 0 4px var(--Gray-7)' }}>

                <div className='text-box-typ' style={{ border: '0' }}>
                  <p className='lbl-typ' > Confirmation No: </p>
                  <p>{transferFromDb.adminTransactionNo}</p>
                </div>

                <div className='text-box-typ'>
                  <p className='lbl-typ' > Admin Note: </p>
                  <p>{transferFromDb.adminNote}</p>
                </div>

                <div className='text-box-typ' style={{ height: '20rem', overflow: 'scroll' }}>
                  <img
                    src={transferFromDb.adminReceipt}
                    alt="Admin confirmation recepit"
                    style={{ width: '100%', height: 'auto', margin: 'auto' }}
                  />
                </div>
              </div>
            </>
          ) : (null)
        )}
      </div>

    </form>
  )
}
