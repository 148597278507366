import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const currencyApi = createApi({
    reducerPath: "currencyApi",
    baseQuery: fetchBaseQuery({
        // baseUrl:"http://localhost:5058/api/"
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers: Headers, api) => {
            const token = localStorage.getItem("token");
            // token && headers.append("Authorization", `Bearer ${token}`);
            if (token) {
                headers.append("Authorization", `Bearer ${token}`);
            }
        }
    }),
    tagTypes: ["Currencies"],

    endpoints: (builder) => ({
        getCurrencies: builder.query({
            query: () => ({
                url:"Currencies"
            }),
            providesTags:["Currencies"]
        }),

        getCurrencyById: builder.query({
            query: (id) => ({
                url:`Currencies/${id}`
            }),
            providesTags:["Currencies"]
        }),

        createCurrency: builder.mutation({
            query: (data) => ({
                url: "Currencies",
                method: "POST",
                body: data
            }),
            invalidatesTags:["Currencies"]
        }),

        updateCurrency: builder.mutation({
            query: ({data,id}) => ({
                url: `Currencies/${id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags:["Currencies"]
        }),

        deleteCurrency: builder.mutation({
            query: (id) => ({
                url: `Currencies/${id}`,
                method: "DELETE"
            }),
            invalidatesTags:["Currencies"]
        }),

    }),
});


export const {
    useGetCurrenciesQuery,
    useGetCurrencyByIdQuery,
    useCreateCurrencyMutation,
    useDeleteCurrencyMutation,
    useUpdateCurrencyMutation} = currencyApi;
export default currencyApi;