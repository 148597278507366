import React, { useEffect, useState } from 'react'
import { SD_Roles } from '../Utility/StaticDetails';
import { IUser } from '../interfaces/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../Redux/Store';
import { IUserRegister } from '../interfaces/IUserRegister';
import { useRegisterUserMutation } from '../Api/AuthApi';
import IApiResponse from '../interfaces/IApiResponse';
import loadingGif from "../images/gifs/addLoadingGif.gif"
import toastNotify from '../Helper/ToastNotify';
import { useGetStatesQuery } from '../Api/StateApi';
import { setState } from '../Redux/StateSlice';
import { IState } from '../interfaces/IState';
import { Recaptcha } from "../components/MainLayout/Recaptcha"
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';


interface Props {
    verified: boolean
    setVerified: React.Dispatch<React.SetStateAction<boolean>>
    captchaRef: React.RefObject<ReCAPTCHA>
}


export const Register = ({ verified, setVerified, captchaRef }: Props) => {

    const TITLE = 'Sign Up | Abedi-FX';

    const navigate = useNavigate();
    const userInfo: IUser = useSelector((state: RootState) => state.userAuthStore);
    const [loading, setLoading] = useState(false);
    const [confirmPass, setConfirmPass] = useState("");
    const [newUser, setNewUser] = useState<IUserRegister>(() => {
        return {
            fullName: "",
            phoneNumber: "",
            email: "",
            streetAddress: "",
            city: "",
            postalCode: "",
            stateId: 0,
            password: "",
            role: "customer"
        }
    });


    const dispatch = useDispatch();

    const [countryId, setCountryId] = useState(0);

    const statesData = useGetStatesQuery(null);

    useEffect(() => {
        if (!statesData.isLoading) {
            dispatch(setState(statesData.data.result));
        }

        // for resting captcha
        if (captchaRef.current !== null) {
            captchaRef.current.reset();
        }
        setVerified(false);

    }, [statesData.isLoading]);


    const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setNewUser(() => {
            return { ...newUser, [name]: value }
        });
    };

    const handleChangeCountry = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        setCountryId(parseInt(evt.target.value));
        setNewUser(() => {
            return { ...newUser, stateId: 0 }
        });
    }

    const [registerUser] = useRegisterUserMutation();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        if (newUser.password !== confirmPass) {
            toastNotify("Passwords are not match!", "error");
            // console.log("Passwords are not match!")
            setLoading(false);
            return;
        };

        if (newUser.stateId === 0) {
            toastNotify("Please select a province.", "error");
            // console.log("Passwords are not match!")
            setLoading(false);
            return;
        };



        const response: IApiResponse = await registerUser(newUser);
        if (response.data) {
            // console.log(response.data);
            setNewUser(
                {
                    fullName: "",
                    phoneNumber: "",
                    email: "",
                    streetAddress: "",
                    city: "",
                    postalCode: "",
                    stateId: 0,
                    password: "",
                    role: ""
                }
            );
            setConfirmPass("");

            if (captchaRef.current !== null) {
                captchaRef.current.reset();
            }
            setVerified(false);

            navigate("/login");
            toastNotify("Registeration was successfull");
            // console.log("Registeration was successfull");


        } else if (response.error) {
            toastNotify(response.error.data.errorMessages[0], "error");
            // console.log(response.error.data, "error");
            // console.log(response.error.data.errorMessages[0]);
            // console.log(response);
        };

        setLoading(false);

    };

    if (statesData.isLoading) {
        return <h2 className='register-page' style={{ color: 'var(--Gray-2)' }}>Data is loading...</h2>
    };


    let countryLiat: any = [{ id: 0, name: '--Select Country--' }];
    statesData.data.result.forEach((k: IState) => {
        let id = k.country.id;
        let name = k.country.name;
        // if (!(id in countryLiat)) {
        // if (!(Object.hasOwn(countryLiat, id))) {
        //     countryLiat.push({ id, name })
        // }
        if (!countryLiat.some((el: { id: number; }) => el.id === id)) {
            countryLiat.push({ id, name });
        }
    });


    return (
        <HelmetProvider>

            <div className='register-page'>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                {/* <div className="register-page-info">
                <div>
                    <div className="register-page-info-icon">
                        <i className="bi bi-cash-coin"></i>
                        <h2 className="info-title">Money Transfer</h2>
                    </div>
                    <div className="description">
                        <h5 className="info-title">Money Transfer</h5>
                        <p className="description">Send money back home to your loved ones</p>
                    </div>
                </div>
            </div> */}

                <div className='container'>
                    <form method="post" className="register-form" onSubmit={handleSubmit}>
                        {/* <Link to="/" className='closeX'>X</Link> */}
                        <h3 className="list-title" style={{ marginTop: "0" }}>Create An Account</h3>
                        <input
                            type="text"
                            name='fullName'
                            className="input-typ"
                            placeholder="Full Name"
                            required
                            value={newUser.fullName}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name='phoneNumber'
                            className="input-typ"
                            placeholder="Phone Number"
                            required
                            value={newUser.phoneNumber}
                            onChange={handleChange}
                        />
                        <input
                            type="email"
                            name='email'
                            className="input-typ"
                            placeholder="Email Adrress"
                            required
                            value={newUser.email}
                            onChange={handleChange}
                        />
                        <input
                            type="text"
                            name='streetAddress'
                            className="input-typ"
                            placeholder="Street Address"
                            required
                            value={newUser.streetAddress}
                            onChange={handleChange}
                        />
                        <div style={{ display: 'flex', gap: '0.5rem' }}>
                            <input
                                type="text"
                                name='city'
                                className="input-typ"
                                style={{ width: '50%' }}
                                placeholder="City"
                                required
                                value={newUser.city}
                                onChange={handleChange}
                            />
                            <input
                                type="text"
                                name='postalCode'
                                className="input-typ"
                                style={{ width: '50%' }}
                                placeholder="Postal Code"
                                required
                                value={newUser.postalCode}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>

                            <select
                                className="input-typ"
                                style={{ width: '50%' }}
                                // name="countryId"
                                value={countryId}
                                onChange={handleChangeCountry}>
                                {/* <option key={-1} value={0} disabled selected>--Select Province--</option> */}
                                {countryLiat.map((c: any) => {
                                    return (
                                        <option key={c.id} value={c.id}>
                                            {c.name}
                                        </option>
                                    );
                                })}
                            </select>

                            <select
                                className="input-typ"
                                style={{ width: '50%' }}
                                name="stateId"
                                required
                                value={newUser.stateId}
                                onChange={handleChange}>
                                {/* <option key={-1} value={0} disabled selected>--Select Province--</option> */}
                                <option key={0} value={0}> --Select Province-- </option>
                                {statesData.data.result.map((s: any) => {
                                    if (s.country.id === countryId) {
                                        return (
                                            <option key={s.id} value={s.id}>
                                                {s.name}
                                            </option>
                                        );
                                    };
                                    return null;
                                })}
                            </select>
                        </div>

                        <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
                            <input
                                type="password"
                                name='password'
                                className="input-typ"
                                placeholder="Password"
                                required
                                value={newUser.password}
                                onChange={handleChange}
                            />

                            <input
                                type="password"
                                name='confirmPass'
                                className="input-typ"
                                placeholder="Confirm Password"
                                required
                                value={confirmPass}
                                onChange={(e) => setConfirmPass(e.target.value)}
                            />
                        </div>

                        {/* <label htmlFor="">{ newUser?.role}</label> */}
                        {userInfo.role === SD_Roles.ADMIN ? (
                            <select
                                className="input-typ"
                                name='role'
                                // defaultValue={newUser?.role}
                                onChange={handleChange}
                            >
                                <option >--Select Role--</option>
                                <option value={`${SD_Roles.OWNER}`}>Owner</option>
                                <option value={`${SD_Roles.ADMIN}`}>Admin</option>
                                <option value={`${SD_Roles.CUSTOMER}`}>Customer</option>
                            </select>
                        ) : null}


                        <Recaptcha setVerified={setVerified} captchaRef={captchaRef} />

                        {loading ? (
                            <img src={loadingGif} alt='loading gif' className="list-btn" style={{ marginRight: "0" }} />
                        ) : (
                            <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem' }}>
                                <button onClick={() => navigate(-1)} className="typ-item-btn btnSecondary"> Back </button>

                                {verified ? (
                                    <button className="typ-item-btn btnPrimary" style={{ flexGrow: "1" }}> Submit </button>
                                ) : (
                                    <button className="typ-item-btn btnSecondary" disabled style={{ flexGrow: "1" }}> Submit </button>
                                )}
                            </div>
                        )}


                    </form>
                </div>
            </div>
        </HelmetProvider>
    )
}
