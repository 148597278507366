export enum SD_Roles {
    OWNER = "owner",
    ADMIN = "admin",
    CUSTOMER = "customer",
};

export enum SD_Status {
    ACTIVE = 1,
    LOCKED = 2,
    ARCHIVED = 3,
};


export enum SD_TransferStatus {
    PENDING = "pending",
    APPROVED = "approved",
    REJECTED = "rejected",
    PROCESSING = "processing",
    COMPLETED = "completed",
    ARCHIVED = "archived"
};