import { PriceList } from "../components/Home/PriceList"
import { Helmet, HelmetProvider } from 'react-helmet-async';



export const Home = () => {
  const TITLE = 'Home | Abedi-FX';
  return (
    <HelmetProvider>

      <div className='home-page'>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
        <h1>Global Money Transfer Solutions</h1>
        <PriceList />
      </div>
    </HelmetProvider>
  )
}
