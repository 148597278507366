import { IUser } from '../interfaces/IUser';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/Store';
import AFXLogo from "../images/logo/logo_gray.png"
import { SD_Roles } from '../Utility/StaticDetails';
import { NavLink, Outlet } from 'react-router-dom';
import { useGetStatesQuery } from '../Api/StateApi';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const Dashboard = () => {
  const TITLE = 'My Account | Abedi-FX';

  const userInfo: IUser = useSelector((state: RootState) => state.userAuthStore);
  const statesData = useGetStatesQuery(null);

  if (statesData.isLoading) {
    return <h4>Data is loading...</h4>
  };


  return (
    <HelmetProvider>

      <div className='dashboard'>

        <Helmet>
          <title>{TITLE}</title>
        </Helmet>

        <div className='d-menu'>
          <div>
            <img src={AFXLogo} alt="avatar" className='d-menu-avatar' />
            <h3>{userInfo.fullName}</h3>
            <h3>{userInfo.role}</h3>
          </div>
          <br />
          <div>
            <NavLink className={({ isActive }) => isActive ? "d-menu-activeBtn" : "d-menu-btn"} to='overview'>Overview</NavLink>
            {userInfo.role === SD_Roles.ADMIN ? (
              <NavLink className={({ isActive }) => isActive ? "d-menu-activeBtn" : "d-menu-btn"} to='users'>Users</NavLink>
            ) : (null)}
            <NavLink className={({ isActive }) => isActive ? "d-menu-activeBtn" : "d-menu-btn"} to='transfers'>Transfer</NavLink>
            {/* <NavLink className={({ isActive }) => isActive ? "d-menu-activeBtn" : "d-menu-btn"} to='#'>Transfer to Iran</NavLink> */}
          </div>
        </div>
        <Outlet context={[userInfo, statesData]} />
      </div>
    </HelmetProvider>
  )
}
