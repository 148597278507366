import AFXLogo from "../../images/logo/logo_gray.png"

export const Footer = () => {
    return (
        <footer className="footer">
            <div className="info">
                <img src={AFXLogo} alt="visa-cardinal-logo" />
                <div className='footer-address'>
                    <h2>Global Money Transfer Solutions</h2>
                    <br />
                    <p  > 6359 Hammond Bay Rd, Nanaimo, BC, V9T5Y1 </p>
                    <p>
                        <a href="tel:+12508162943">(250)816-2943</a>
                    </p>
                    <p>
                        <a href="mailto:transfer@abedi-fx.com">transfer@abedi-fx.com</a>
                    </p>
                </div>
                <div className='footer-ezcode'>
                    <small> Copyright (C) 2022 - all rights reserved </small>
                    <small>
                        Site By &nbsp;
                        <a href="https://ezcode.ca" target="_blank">EZcode</a>
                    </small>
                </div>
            </div>
        </footer>
    )
}
