import React, { useState } from 'react'
import { useGetReceiverByIdQuery } from '../../Api/ReceiversApi';
import loadingGif from "../../images/gifs/addLoadingGif.gif";
import { IMoneyTransfer } from '../../interfaces/IMoneyTransfer';
import { Recaptcha } from '../MainLayout/Recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { useGetCurrenciesQuery } from '../../Api/CurrencyApi';
import { ICurrency } from '../../interfaces/ICurrency';
import toastNotify from '../../Helper/ToastNotify';
import IApiResponse from '../../interfaces/IApiResponse';
import { useCreateTransferMutation } from '../../Api/TransferApi';
import { SD_TransferStatus } from '../../Utility/StaticDetails';
import { IReceiver } from '../../interfaces/IReceiver';



interface Props {
  receiverId: number,
  setReceiverId: React.Dispatch<React.SetStateAction<number>>
  verified: boolean
  setVerified: React.Dispatch<React.SetStateAction<boolean>>
  captchaRef: React.RefObject<ReCAPTCHA>
}



export const TransferRequestForm = ({ receiverId, setReceiverId, verified, setVerified, captchaRef }: Props) => {

  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useGetReceiverByIdQuery(receiverId);


  const currencies = useGetCurrenciesQuery(null);

  const [currencyImg, setCurrencyImg] = useState('');

  const [transfer, setTransfer] = useState<IMoneyTransfer>(() => {
    return {
      receiverId: receiverId,
      amount: 0,
      currencyId: 0,
      clientTransactionNo: '',
      clientReceipt: '',
      clientNote: '',
      transferStatus: SD_TransferStatus.PENDING
    }
  });

  const handleCurrencyImg = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement & HTMLTextAreaElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setTransfer(() => {
      return { ...transfer, [name]: value }
    });

    currencies.data?.result.map((c: ICurrency) => {
      if (parseInt(evt.target.value) === c.id) {
        setCurrencyImg(c.country?.imageUrl ? c.country?.imageUrl : '')
      }
      if (parseInt(evt.target.value) === 0) {
        setCurrencyImg('')
      }
    });
  };

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement & HTMLTextAreaElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setTransfer(() => {
      return { ...transfer, [name]: value }
    });

    // currencies.data?.result.map((c: ICurrency) => {
    //   if (transfer.currencyId == c.id) {
    //     setCurrencyImg(c.country?.imageUrl ? c.country?.imageUrl : '')
    //   }
    //   if (transfer.currencyId == 0) {
    //     setCurrencyImg('')
    //   }
    // });
  };

  // ----------------------- handling load IdCard here ----------------------- //
  const [receiptUpload, setReceiptUpload] = useState<any>();
  const [receiptPreview, setReceiptPreview] = useState<string>(() => {
    return ""
  });
  const handleReceiptChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImgTpyeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setReceiptUpload("");
        toastNotify("File must be less than 1 MB", "error");
        return;
      }

      else if (isImgTpyeValid.length === 0) {
        setReceiptUpload("");
        toastNotify("File must be in jpeg, jpg, or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setReceiptUpload(file);

      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;
        setReceiptPreview(imgUrl)
      }
    }
  };

  const [ createTransfer ] = useCreateTransferMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);



    // appends data from the form and local variables to the FromData
    const formData = new FormData();
    if (!transfer.receiverId) {
      toastNotify("No Receiver Selected.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("receiverId", transfer.receiverId.toString());
    }

    if (!transfer.amount) {
      toastNotify("Please enter amount.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("amount", transfer.amount.toString());
    }

    if (!transfer.currencyId) {
      toastNotify("Please select a currency.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("currencyId", transfer.currencyId.toString());
    }

    if (!transfer.clientTransactionNo) {
      toastNotify("Please enter client Transaction No.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("clientTransactionNo", transfer.clientTransactionNo.toString());
    }

    if (!receiptUpload) {
      toastNotify("Please upload transaction recepit.", "error");
      setLoading(false);
      return;
    } else {
      formData.append("clientReceipt", receiptUpload);
    }

    formData.append("clientNote", transfer.clientNote ? transfer.clientNote.toString() : '');
    formData.append("transferStatus", transfer.transferStatus ? transfer.transferStatus.toString() : '');


    // submits the data for CategoryApi
    const response: IApiResponse = await createTransfer(formData);
    if (response.data?.isSuccess) {

      setLoading(false);
      toastNotify("Request submited successfully!");

      setReceiverId(0);

      if (captchaRef.current !== null) {
                captchaRef.current.reset();
            }
      setVerified(false);


    } else {
      // toastNotify(response.error.data.errorMessages[0], "error");
      console.log(response);
    }
    setLoading(false);
  }


  if (isLoading || currencies.isLoading) {
    return <h1>Data is loading...</h1>
  };


  return (
    <form method="post" className='transfer-form' encType='multipart/form-data' onSubmit={handleSubmit}>
      <h3 className="list-title" style={{ marginBottom: "1rem", borderBottom:'1px solid var(--Gray-6)' }}>Money transfer request form</h3>
      <h3 style={{ alignSelf: 'start' }}>Receiver's Information</h3>
      <div style={{ boxShadow: '0 0 4px var(--Gray-7)', width:'100%' }}>
        <div className='text-box-typ'>
          <p className='lbl-typ' > Full Name: </p>
          <p>{data.result.fullName}</p>
        </div>

        <div className='text-box-typ'>
          <p className='lbl-typ' > Phone Number: </p>
          <p>{data.result.phoneNumber}</p>
        </div>

        <div className='text-box-typ'>
          <p className='lbl-typ' > Email: </p>
          <p>{data.result.email}</p>
        </div>

        <div className='text-box-typ'>
          <p className='lbl-typ' > Bank: </p>
          <p>{data.result.bank?.name}</p>
        </div>

        {data.result.state.country.id != 97 ? (
          <>
            <div className='text-box-typ'>
              <p className='lbl-typ' > Bank Transit No: </p>
              <p>{data.result.transitNo}</p>
            </div>

            <div className='text-box-typ'>
              <p className='lbl-typ' > Bank Institution No: </p>
              <p>{data.result.bankInstitutionNo}</p>
            </div>

            <div className='text-box-typ'>
              <p className='lbl-typ' > Bank Account No: </p>
              <p>{data.result.bankAccountNo}</p>
            </div>
          </>
        ) : (
            <>
              <div className='text-box-typ'>
                <p className='lbl-typ' > Card No: </p>
                <p>{data.result?.irCardNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > Bank Account No: </p>
                <p>{data.result?.irAccountNo}</p>
              </div>

              <div className='text-box-typ'>
                <p className='lbl-typ' > IBAN: </p>
                <p>{data.result?.iriban}</p>
              </div>
            </>
        )}

        <hr style={{ border: '0.5px solid var(--Gray-4)' }} />

      </div>
      <br /> <br />


      <div style={{ width: '100%', display:'flex', flexDirection:'column', alignItems:'center' }}>
        <div className='input-typ-box'>
          <input
            type="text"
            name='amount'
            className="input-typ"
            required
            value={transfer.amount === 0 ? '' : transfer.amount}
            onChange={handleChange}
          />
          <label
            htmlFor="bankAccountNo"
            className='lbl-input-typ'
          >
            Amount & Currency
          </label>


          <select
            className="input-typ"
            name="currencyId"
            style={{
              position: 'absolute',
              top: '0',
              right: '4rem',
              width: '5rem',
              height: '2.5rem',
              zIndex: '110',
              borderRadius: '0'
            }}
            required
            value={transfer.currencyId}
            onChange={handleCurrencyImg}
          >
            <option key={0} value={0}> </option>
            {currencies.data?.result.map((c: any) => {
              return (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              );
            })}
          </select>

          <img
            src={currencyImg}
            alt="currency"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              width: '4rem',
              height: '2.5rem',
              zIndex: '110',
              borderRadius: '0 5px 5px 0'
            }}
          />
        </div>

        <div className='input-typ-box'>
          <input
            type="text"
            name='clientTransactionNo'
            className="input-typ"
            required
            value={transfer.clientTransactionNo}
            onChange={handleChange}
          />
          <label
            htmlFor="bankAccountNo"
            className='lbl-input-typ'
          >
            Transaction No
          </label>
        </div>





        <input
          type="file"
          className="input-typ"
          style={{
            height: '1.5rem',
            marginRight: 'auto',
            marginBottom: '1rem',
            padding: '0',
          }}
          onChange={handleReceiptChange}
        />

        <div className='input-typ-box'>
          <input
            name='clientNote'
            className="input-typ"
            value={transfer.clientNote}
            onChange={handleChange}
          />
          <label
            htmlFor="bankInstitutionNo"
            className='lbl-input-typ'
          >
            Note
          </label>
        </div>
          <Recaptcha setVerified={setVerified} captchaRef={captchaRef} />


        {loading ? (
          <img src={loadingGif} alt='loading gif' className="list-btn" style={{ marginRight: "0" }} />
        ) : (
          <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem', width:'19rem' }}>
            <button onClick={() => setReceiverId(0)} className="typ-item-btn btnDangerB"> Back </button>

            {verified ? (
              <button className="typ-item-btn btnPrimary" style={{ flexGrow: "1" }}> Submit </button>
            ) : (
              <button className="typ-item-btn btnSecondary" disabled style={{ flexGrow: "1" }}> Submit </button>
            )}
          </div>
        )}
      </div>
    </form>
  )
}
