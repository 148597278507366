import { createSlice } from '@reduxjs/toolkit';
import { IUser } from '../interfaces/IUser';

export const userState: IUser = {
    id: "",
    userId: 0,
    fullName: "",
    email: "",
    role: "",
};

export const userAuthSlice = createSlice({
    name: 'userAuth',
    initialState: userState,
    reducers: {
        setLoggedInUser: (state, action) => {
            state.id = action.payload.id;
            state.userId = action.payload.userId;
            state.fullName = action.payload.fullName;
            state.email = action.payload.email;
            state.role = action.payload.role;
        },
    },
});


export const { setLoggedInUser } = userAuthSlice.actions;
export const userAuthReducer = userAuthSlice.reducer;