import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    receiver: [],
};

export const receiverSlice = createSlice({
    name: 'receiver',
    initialState: initialState,
    reducers: {
        setReceiver: (state, action) => {
            state.receiver= action.payload;
        },
    },
});


export const { setReceiver } = receiverSlice.actions;
export const receiverReducer = receiverSlice.reducer;