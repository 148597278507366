import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bank: [],
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState: initialState,
    reducers: {
        setBank: (state, action) => {
            state.bank= action.payload;
        },
    },
});


export const { setBank } = bankSlice.actions;
export const bankReducer = bankSlice.reducer;