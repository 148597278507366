import React, { useEffect, useState } from 'react';
import { useCreatePriceMutation } from '../../Api/PriceApi';
import { ICreatePrice } from '../../interfaces/ICreatePrice';
import toastNotify from '../../Helper/ToastNotify';
import IApiResponse from '../../interfaces/IApiResponse';
import loadingGif from "../../images/gifs/addLoadingGif.gif";
import { IState } from '../../interfaces/IState';
import withAdminAuth from '../../HOC/withAdminAuth';

interface Props{
  states: any
}

const PriceAdd = ({ states }: Props) => {

  const [loading, setLoading] = useState(false);
  const [newPrice, setNewPrice] = useState<ICreatePrice>(() => {
    return {
      currencyId: 0,
      weBuyPrice: 0,
      weSellPrice: 0
    }
  });

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setNewPrice(() => {
      return { ...newPrice, [name]: value }
    });
  };


  // const MINUTE_MS = 30000;
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     try {
  //       console.log('Logs every minute');
  //       setNewPrice(() => {
  //         return { ...newPrice, currencyId: 0, weBuyPrice: 0, weSellPrice: 0 }
  //       });
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }, MINUTE_MS);
  //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, [])


  const [createPrice] = useCreatePriceMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (newPrice?.currencyId === 0) {
      toastNotify("Please select country.", "error");
      setLoading(false);
      return;
    }

    if (newPrice?.weBuyPrice === undefined || newPrice?.weBuyPrice === 0) {
      toastNotify("Please enter Buy Price.", "error");
      setLoading(false);
      return;
    }

    if (newPrice?.weSellPrice === undefined || newPrice?.weSellPrice === 0) {
      toastNotify("Please enter Sell Price.", "error");
      setLoading(false);
      return;
    }

    // appends data from the form and local variables to the FromData
    const formData = new FormData();
    formData.append("currencyId", newPrice?.currencyId !== undefined ? newPrice?.currencyId.toString() : '');
    formData.append("weBuyPrice", newPrice?.weBuyPrice !== undefined ? newPrice?.weBuyPrice.toString() : '');
    formData.append("weSellPrice", newPrice?.weSellPrice !== undefined ? newPrice?.weSellPrice.toString() : '');


    // submits the data for CategoryApi
    const response: IApiResponse = await createPrice(formData);
    // const response = await createCategory(newCategory);
    if (response.data?.isSuccess) {
      newPrice.currencyId = 0;
      newPrice.weBuyPrice = 0;
      newPrice.weSellPrice = 0;


      setLoading(false);
      // console.log(response)
      toastNotify("New Price added successfully!");
    } else {
      // console.log(response)
      toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
    return;

  }

  let countryLiat: any = [{ id: 0, name: '' }];
  states.forEach((k: IState) => {
    let id = k.country.id;
    let name = k.country.name;
    if (!countryLiat.some((el: { id: number; }) => el.id === id)) {
      countryLiat.push({ id, name });
    }
  });


  return (
    <form className='price-add-form' method='put' encType='multipart/form-data' onSubmit={handleSubmit}>
      <h3>Add today price</h3>
      <div style={{ position: 'relative', width: '100%' }}>
        <select
          className="input-typ"
          name="currencyId"
          required
          value={newPrice?.currencyId}
          onChange={handleChange}>
          <option key={0} value={0} ></option>
          <option key={1} value={1} >Canada</option>
          <option key={2} value={2} >United States</option>
          <option key={3} value={3} >European Union</option>
          <option key={4} value={4} >United Kingdom</option>
          <option key={7} value={7} >Tether</option>
          {/* {countryLiat.map((s: any) => {
            return (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            );
          })} */}
        </select>
        <label
          htmlFor="fcurrency"
          className='lbl-input-typ'
        >
          Select Country
        </label>
      </div>

      <div style={{ position: 'relative', width: '100%' }}>
        <input
          type="text"
          className='input-typ'
          name="weBuyPrice"
          value={newPrice?.weBuyPrice === 0 ? '' : newPrice?.weBuyPrice}
          onChange={handleChange}
        />
        <label
          htmlFor="fcurrency"
          className='lbl-input-typ'
        >
          We Buy Price
        </label>
        {/* <input
          style={{position:'absolute', top:'14px', right:'10px'}}
          type='checkbox'
        /> */}
      </div>

      <div style={{ position: 'relative', width: '100%' }}>
        <input
          className='input-typ'
          name="weSellPrice"
          value={newPrice?.weSellPrice === 0 ? '' : newPrice?.weSellPrice}
          onChange={handleChange}
        />
        <label
          htmlFor="fcurrency"
          className='lbl-input-typ'
        >
          We Sell Price
        </label>
      </div>

      {/* --------------- Buttons --------------- */}

      <div className='card-btn-box'>
        {loading ? (
          <img src={loadingGif} className="list-btn" style={{ marginRight: "0" }} />
        ) : (
          <button className="typ-item-btn btnPrimary">Submit</button>
        )}
      </div>
    </form>
  );
};

export default withAdminAuth(PriceAdd);
