import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/header.css';
import './styles/home.css';
import './styles/footer.css';
import './styles/register.css';
import './styles/dashboard.css';
import Main from './pages/Main';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './Redux/Store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <Provider store={store} >
    <BrowserRouter>
      <React.StrictMode>
        <ToastContainer />
        <Main />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>
);
