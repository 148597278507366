import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    price: [],
};

export const priceSlice = createSlice({
    name: 'price',
    initialState: initialState,
    reducers: {
        setPrice: (state, action) => {
            state.price= action.payload;
        },
    },
});


export const { setPrice } = priceSlice.actions;
export const priceReducer = priceSlice.reducer;