import { useGetPricesQuery } from '../../Api/PriceApi';
import { IPrice } from '../../interfaces/IPrice';

export const PriceList = () => {

    const today = new Date();
    // const month = today.getMonth()+1;
    // const year = today.getFullYear();
    // const date = today.getDate();
    // const hour = today.getHours();
    // const minute = today.getMinutes();
    // const second = today.getSeconds();
    // const ampm = (hour >= 12) ? "PM" : "AM";
    // const currentDate = `${month}/${date}/${year}, ${hour}:${minute}:${second} ${ampm}`;
    // console.log(currentDate);

    const { data, isLoading } = useGetPricesQuery(null);

    if (isLoading) {
        return <h4>Data is loading...</h4>
    };

    return (
        <div className='home-price-box'>
            <div className='home-price-title'>
                <p className='home-price-col'>Currency</p>
                <p className='home-price-col country-name'>Country</p>
                <p className='home-price-col'>We Buy</p>
                <p className='home-price-col'>We Sell</p>
                <p className='home-price-col'>Last Update</p>
            </div>

            {data.result.map((price: IPrice) => {
                return (
                    // {console.log(new Date(p.createDate).toLocaleString())}
                    // {console.log(currentDate)}
                    new Date(price.updatedDate).toDateString() === today.toDateString() ? (
                        <div className='home-price-list' key={price.id}>
                            <div className='home-price-col'>
                                <img src={price.currency.country.imageUrl} alt='abedi-fx country flag' className='flag' />
                                <p style={{ width: 'min-content', marginLeft: '0.5rem' }}>{price.currency.name}</p>
                            </div>
                            <p className='home-price-col country-name'>{price.currency.country.name}</p>
                            <p className='home-price-col'>{price.weBuyPrice}</p>
                            <p className='home-price-col'>{price.weSellPrice}</p>
                            <div className='home-price-col' style={{ flexDirection: 'column' }} >
                                <p style={{ borderRadius: '5px' }}>{new Date(price.updatedDate).toLocaleDateString()}</p>
                                <p style={{ borderRadius: '5px' }}>{new Date(price.updatedDate).toLocaleTimeString()}</p>
                            </div>
                        </div>
                    ) : (null)
                )
            })}

        </div>
    )
}
