import { configureStore } from "@reduxjs/toolkit";
import authApi from "../Api/AuthApi";
import { userAuthReducer } from "./UserAuthSlice";
import { stateReducer } from "./StateSlice";
import stateApi from "../Api/StateApi";
import { priceReducer } from "./PriceSlice";
import priceApi from "../Api/PriceApi";
import { bankReducer } from "./BankSlice";
import bankApi from "../Api/BankApi";
import { receiverReducer } from "./ReceiversSlice";
import { currencyReducer } from "./CurrencySlice";
import currencyApi from "../Api/CurrencyApi";
import { transferReducer } from "./TransferSlice";
import transferApi from "../Api/TransferApi";
import receiverApi from "../Api/ReceiversApi";




const store = configureStore({
    reducer: {
        userAuthStore: userAuthReducer,
        stateStore: stateReducer,
        priceStore: priceReducer,
        receiverStore: receiverReducer,
        bankStore: bankReducer,
        currencyStore: currencyReducer,
        transferStore: transferReducer,

        // these items should be set after adding each api
        [authApi.reducerPath]: authApi.reducer,
        [stateApi.reducerPath]: stateApi.reducer,
        [priceApi.reducerPath]: priceApi.reducer,
        [receiverApi.reducerPath]: receiverApi.reducer,
        [bankApi.reducerPath]: bankApi.reducer,
        [currencyApi.reducerPath]: currencyApi.reducer,
        [transferApi.reducerPath]: transferApi.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(authApi.middleware)
        .concat(stateApi.middleware)
        .concat(priceApi.middleware)
        .concat(receiverApi.middleware)
        .concat(bankApi.middleware)
        .concat(currencyApi.middleware)
        .concat(transferApi.middleware)
});


export type RootState = ReturnType<typeof store.getState>;
export default store;