import { Fragment, useState } from "react";
import { IUser } from "../../interfaces/IUser"
import { ReceiversListRow } from "./ReceiversListRow"
import { IReceiver } from "../../interfaces/IReceiver";


interface Props {
    receivers: IReceiver[],
    setReceiverId: React.Dispatch<React.SetStateAction<number>>
}


export const ReceiversList = ({ receivers, setReceiverId }: Props) => {

    // gets the string from input field
    const [searchString, setSearchString] = useState('');

    // empty array to push receivers that contain searchString
    let searchedReceivers : IReceiver[] = [];

    const handleChangeSearchString = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(() => {
            return (evt.target.value).toLowerCase();
        });
    }

    // checks to see receiver has the searchString or not, if yes then pushes the receiver to the filteredreceivers
    for (const [key, value] of Object.entries(receivers)) {
        if (value.fullName?.toLowerCase().includes(searchString) ||
            value.email?.toLowerCase().includes(searchString) ||
            value.phoneNumber?.toLowerCase().includes(searchString)) {
            searchedReceivers.push(value);
        }
    };

    return (
        <div className="d-receiver-list-container">
            <h3 className="list-title" style={{ margin: "0.5rem 0 0.5rem 0", borderBottom:'1px solid var(--Gray-6)' }}>Receivers List</h3>
            <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
                <input
                    type="text"
                    className='input-typ'
                    style={{width: '100%', margin:'0.5rem 0.1rem', alignSelf:'start'}}
                    value={searchString}
                    onChange={handleChangeSearchString}
                    placeholder="Search by Name, Eamil, or Phone number"
                />

                {/* <input
                    type="text"
                    className='input-typ'
                    style={{width: '14rem', margin:'0.5rem', marginLeft:'0.5rem', alignSelf:'start'}}
                    // name='phoneNumber'
                    // value={receiver.phoneNumber}
                    // disabled={!updateBtn}
                    placeholder="Search Name or Eamil or Bank"
                /> */}
            </div>

            <div className='d-receiver-list'>
                {searchedReceivers.length > 0 ? (
                    searchedReceivers.map((receiver: any) => {
                        return (
                            <ReceiversListRow key={receiver.id} receiver={receiver} setReceiverId={setReceiverId} />
                        )
                    })
                ) : (
                        <>
                            <h4>There is no Receiver to show!</h4>
                            <h4>Before transfering money, you need to add receiver info.</h4>
                        </>
                )}
            </div>
        </div>
    );
};
