import React, { useState } from 'react'
import { useDeletePriceMutation, useUpdatePriceMutation } from '../../Api/PriceApi';
import { IPrice } from '../../interfaces/IPrice';
import { IPriceUpdate } from '../../interfaces/IPriceUpdate';
import toastNotify from '../../Helper/ToastNotify';
import IApiResponse from '../../interfaces/IApiResponse';


interface Props {
    price: IPrice
}

export const PriceListRowAdmin = ({ price }: Props) => {

    const [updateBtn, setUpdateBtn] = useState(false);
    const handleUpdateBtn = () => {
        setUpdateBtn(!updateBtn);
    };
    const [newPrice, setNewPrice] = useState<IPriceUpdate>(() => {
        return {
            id: price.id,
            weBuyPrice: price.weBuyPrice,
            weSellPrice: price.weSellPrice,
            currencyId : price.currency.id
        }
    });

    const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setNewPrice(() => {
            return { ...newPrice, [name]: value }
        });
    };

    const [loading, setLoading] = useState(false);
    const [updatePrice] = useUpdatePriceMutation();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // appends data from the form and local variables to the FromData
        const formData = new FormData();
        formData.append("id", newPrice.id.toString());
        formData.append("weBuyPrice", newPrice.weBuyPrice.toString());
        formData.append("weSellPrice", newPrice.weSellPrice.toString());
        formData.append("currencyId", newPrice.currencyId.toString());


        // submits the data for CategoryApi
        const response: IApiResponse = await updatePrice({ data: formData, id: newPrice.id });
        if (response.data?.isSuccess) {

            handleUpdateBtn();
            setLoading(false);
            toastNotify("Price updated successfully!");
            handleUpdateBtn();
        } else {
            toastNotify(response.error.data.errorMessages[0], "error");
        }
        setLoading(false);
    }

    const [deletPrice] = useDeletePriceMutation();
    const handleDelete = async () => {
        setLoading(true);

        // submits the data for CategoryApi
        const response: IApiResponse = await deletPrice(newPrice.id);
        if (response.data?.isSuccess) {
            handleUpdateBtn();
            setLoading(false);
            toastNotify("Price Deleted successfully!");
        } else {
            toastNotify(response.error.data.errorMessages[0], "error");
        }
        setLoading(false);
    }


    if (loading) {
        return <h4>Data is loading...</h4>
    }




    return (
        <form className='admin-price-row' method='put' encType='multipart/form-data' onSubmit={handleSubmit}>
            <div className='price-col'>
                <img src={price.currency.country.imageUrl} style={{ width: '50px', height: 'auto', borderRadius: '7px' }} />
                <p style={{ width: 'min-content', marginLeft: '0.5rem' }}>{price.currency.name}</p>
            </div>
            <p className='price-col'>{price.currency.country.name}</p>
            {!updateBtn ? (
                <>
                    <p className='price-col'>{price.weBuyPrice}</p>
                    <p className='price-col'>{price.weSellPrice}</p>
                </>
            ) : (
                <>
                    <input
                        className='price-col-in'
                        name='weBuyPrice'
                        value={newPrice?.weBuyPrice}
                        onChange={handleChange}
                    />

                    <input
                        className='price-col-in'
                        name='weSellPrice'
                        value={newPrice?.weSellPrice}
                        onChange={handleChange}
                    />
                </>
            )}



            <div className='price-col' style={{ flexDirection: 'column' }} >
                <p style={{ borderRadius: '5px' }}>{new Date(price.updatedDate).toLocaleDateString()}</p>
                <p style={{ borderRadius: '5px' }}>{new Date(price.updatedDate).toLocaleTimeString()}</p>
            </div>
            {!updateBtn ? (
                <div className='price-col'>
                    {/* <i onClick={handleUpdateBtn}  className="bi bi-three-dots"></i> */}
                    <p onClick={handleUpdateBtn} className="typ-item-btn btnWarning">
                        Edit
                    </p>
                </div>
            ) :
                <div className='price-col' style={{ display: 'flex', gap: '0.5rem', flexDirection:'column' }}>
                    <button className="typ-item-btn btnPrimary">Update</button>
                    <button onClick={handleUpdateBtn} className="typ-item-btn btnSecondary">Cancel</button>
                    <button onClick={handleDelete} className="typ-item-btn btnDanger">Delete</button>
                </div>
            }
        </form>
    )
}
