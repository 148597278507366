import React, { useEffect, useState } from 'react';
// import jwt_decode from "jwt-decode";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IUserLogin } from '../interfaces/IUserLogin';
import { useLoginUserMutation } from '../Api/AuthApi';
import IApiResponse from '../interfaces/IApiResponse';
import { IUser } from '../interfaces/IUser';
import { setLoggedInUser } from '../Redux/UserAuthSlice';
import loadingGif from "../images/gifs/addLoadingGif.gif"
import toastNotify from '../Helper/ToastNotify';
import { jwtDecode } from "jwt-decode";
import { Recaptcha } from '../components/MainLayout/Recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet, HelmetProvider } from 'react-helmet-async';


interface Props {
    verified: boolean
    setVerified: React.Dispatch<React.SetStateAction<boolean>>
    captchaRef: React.RefObject<ReCAPTCHA>
}

export const Login = ({ verified, setVerified, captchaRef }: Props) => {
    const TITLE = 'Login | Abedi-FX';
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginInfo, setLoginInfo] = useState<IUserLogin>({
        userName: "",
        password: ""
    });


    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setLoginInfo(() => {
            return { ...loginInfo, [name]: value }
        });
    };


    // refresh
    // navigate(0)

    useEffect(() => {

        if (captchaRef.current !== null) {
            captchaRef.current.reset();
        }
        setVerified(false);
    }, [setVerified]);

    const [LoginUser] = useLoginUserMutation();
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        const response: IApiResponse = await LoginUser(loginInfo);
        if (response.data) {
            const { token } = response.data.result;
            localStorage.setItem("token", token);
            const { id, userId, fullName, email, role }: IUser = jwtDecode(token);
            dispatch(setLoggedInUser({ id, userId, fullName, email, role }));
            // navigate("/admin");
            navigate("/dashboard/overview");

            // console.log(response.data);
            setLoginInfo(
                { userName: "", password: "" }
            );
            toastNotify("You are logged in successfully!");
            // console.log("You are logged in successfully!");

            if (captchaRef.current !== null) {
                captchaRef.current.reset();
            }
            setVerified(false);

            setLoading(false);
            // window.location.reload();

        } else if (response.error) {
            toastNotify(response.error.data.errorMessages[0], "error");
            // console.log(response.error.data);
            // setError(response.error.data.errorMessages[0]);
        };

        setLoading(false);
    };

    // const handleEnter = (e: any) => {
    //     if (e.key === 'Enter') {
    //         handleSubmit(e);
    //     };
    // };

    return (
        <HelmetProvider>

            <div className='login-page'>

                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                <div className='container'>
                    <form method="post" onSubmit={handleSubmit} className="register-form">
                        {/* <Link to="/" className='closeX'>X</Link> */}
                        <h3 className="list-title" style={{ marginTop: "0" }}>Log in</h3>
                        <input
                            type="text"
                            name='userName'
                            className="input-typ"
                            placeholder="Enter Username"
                            required
                            value={loginInfo.userName}
                            onChange={handleChange}
                        />

                        <input
                            type="password"
                            name='password'
                            className="input-typ"
                            placeholder="Enter Password"
                            required
                            value={loginInfo.password}
                            onChange={handleChange}
                        // onKeyDown={handleEnter}
                        />

                        <Recaptcha setVerified={setVerified} captchaRef={captchaRef} />


                        {loading ? (
                            <img src={loadingGif} className="list-btn" style={{ marginRight: "0" }} />
                        ) : (
                            <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem' }}>
                                <button onClick={() => navigate(-1)} className="typ-item-btn btnSecondary"> Back </button>

                                {verified ? (
                                    <button className="typ-item-btn btnPrimary" style={{ flexGrow: "1" }} > Log in </button>
                                ) : (
                                    <button className="typ-item-btn btnSecondary" disabled style={{ flexGrow: "1" }}> Log in </button>
                                )}

                            </div>
                        )}


                        <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem' }}>
                            <p>Do not have an account?</p>
                            <Link to="/register" style={{ textDecoration: 'none' }}> Register </Link>
                        </div>

                    </form>
                </div>
            </div>
        </HelmetProvider>
    )
}
