import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const stateApi = createApi({
    reducerPath: "stateApi",
    baseQuery: fetchBaseQuery({
        // baseUrl:"http://localhost:5058/api/"
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers: Headers, api) => {
            const token = localStorage.getItem("token");
            token && headers.append("Authorization", `Bearer ${token}`);
        }
    }),
    tagTypes: ["States"],

    endpoints: (builder) => ({
        getStates: builder.query({
            query: () => ({
                url:"States"
            }),
            providesTags:["States"]
        }),

        getStateById: builder.query({
            query: (id) => ({
                url:`States${id}`
            }),
            providesTags:["States"]
        }),

        createState: builder.mutation({
            query: (data) => ({
                url: "States",
                method: "POST",
                body: data
            }),
            invalidatesTags:["States"]
        }),

        updateState: builder.mutation({
            query: ({data,id}) => ({
                url: `States/${id}`,
                method: "PUT",
                body: data
            })
        }),

        deleteState: builder.mutation({
            query: (id) => ({
                url: `States/${id}`,
                method: "DELETE"
            }),
            invalidatesTags:["States"]
        }),

    }),
});


export const {
    useGetStatesQuery,
    useGetStateByIdQuery,
    useCreateStateMutation,
    useDeleteStateMutation,
    useUpdateStateMutation} = stateApi;
export default stateApi;