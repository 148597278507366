import { useUpdateUserMutation } from '../../Api/AuthApi'
import { IUser } from '../../interfaces/IUser';
import { useState } from 'react';
import { useGetStatesQuery } from '../../Api/StateApi';
import { IState } from '../../interfaces/IState';
import toastNotify from '../../Helper/ToastNotify';
import { IUserUpdate } from '../../interfaces/IUserUpdate';
import IApiResponse from '../../interfaces/IApiResponse';
import { SD_Roles, SD_Status } from '../../Utility/StaticDetails';
import { useOutletContext } from 'react-router-dom';


interface Props {
  userFullInfo: IUser
}


export const UserProfile = ({ userFullInfo }: Props) => {

  const [userInfo, statesData]: any = useOutletContext();

  const [updateBtn, setUpdateBtn] = useState(false);

  const [countryId, setCountryId] = useState(userFullInfo.state?.country?.id);

  const [idCardUpload, setIdCardUpload] = useState<any>();
  const [idCardPreview, setIdCardPreview] = useState<string>(() => {
    return ""
  });

  const [imgUpload, setImgUpload] = useState<any>();
  const [imgPreview, setImgPreview] = useState<string>(() => {
    return ""
  });

  const [userDto, setUserDto] = useState<IUserUpdate>(() => {
    return {
      id: userFullInfo.id,
      userId: userFullInfo.userId,
      fullName: userFullInfo.fullName,
      image: userFullInfo.image,
      idCard: userFullInfo.idCard,
      phoneNumber: userFullInfo.phoneNumber,
      streetAddress: userFullInfo.streetAddress,
      city: userFullInfo.city,
      postalCode: userFullInfo.postalCode,
      stateId: userFullInfo.state?.id,
      statusId: userFullInfo.userStatus?.id
    }
  });

  const handleChangeCountry = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryId(parseInt(evt.target.value));
    setUserDto(() => {
      return { ...userDto, stateId: 0 }
    });
  }

  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setUserDto(() => {
      return { ...userDto, [name]: value }
    });
  };


  const handleUpdateBtn = () => {
    setUpdateBtn(!updateBtn);

    setCountryId(() => { return (userFullInfo.state?.country?.id) });

    setIdCardPreview(() => { return ('') });
    setIdCardUpload(() => { return ('') });

    setImgPreview(() => { return ('') });
    setImgUpload(() => { return ('') });
  };

  const handleCancelBtn = () => {
    setUpdateBtn(!updateBtn);

    setCountryId(() => { return (userFullInfo.state?.country?.id) });

    setUserDto(() => {
      return { ...userDto, ...userFullInfo }
    });

    setUserDto(() => {
      return { ...userDto, stateId: userFullInfo.state?.id }
    });

    setIdCardPreview(() => { return ('') });
    setIdCardUpload(() => { return ('') });

    setImgPreview(() => { return ('') });
    setImgUpload(() => { return ('') });
  };



  // ----------------------- handling load IdCard here ----------------------- //
  const handleIdCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      const imgType = file.type.split("/")[1];
      const validImgTypes = ["jpeg", "jpg", "png"];

      const isImgTpyeValid = validImgTypes.filter((e) => {
        return e === imgType;
      });

      if (file.size > 1000 * 1024) {
        setIdCardUpload("");
        toastNotify("File must be less than 1 MB", "error");
        return;
      }

      else if (isImgTpyeValid.length === 0) {
        setIdCardUpload("");
        toastNotify("File must be in jpeg, jpg, or png", "error");
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setIdCardUpload(file);

      reader.onload = (e) => {
        const imgUrl = e.target?.result as string;
        setIdCardPreview(imgUrl)
      }
    }
  }
  // ----------------------- ----------------------- ----------------------- //

  const [loading, setLoading] = useState(false);
  const [updateProfile] = useUpdateUserMutation();
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    if (countryId === 0) {
      toastNotify("Please select a country.", "error");
      setLoading(false);
      return;
    }

    if (userDto.stateId == 0) {
      toastNotify("Please select a province.", "error");
      setLoading(false);
      return;
    }

    // appends data from the form and local variables to the FromData
    const formData = new FormData();
    // formData.append("id", userDto.id.toString());
    formData.append("id", userDto.id ? userDto.id.toString() : '');
    formData.append("userId", userDto.userId ? userDto.userId.toString() : '');
    formData.append("fullName", userDto.fullName.toString());
    formData.append("image", imgUpload ? imgUpload : '');
    formData.append("idCard", idCardUpload);
    formData.append("phoneNumber", userDto.phoneNumber ? userDto.phoneNumber.toString() : '');
    formData.append("streetAddress", userDto.streetAddress ? userDto.streetAddress.toString() : '');
    formData.append("city", userDto.city ? userDto.city.toString() : '');
    formData.append("postalCode", userDto.postalCode ? userDto.postalCode.toString() : '');
    formData.append("stateId", userDto.stateId ? userDto.stateId.toString() : '');
    formData.append("statusId", userDto.statusId ? userDto.statusId.toString() : '');



    // submits the data for CategoryApi
    const response: IApiResponse = await updateProfile({ data: formData, id: userDto.id });
    if (response.data?.isSuccess) {

      handleUpdateBtn();
      setLoading(false);
      toastNotify("Profile updated successfully!");
    } else {
      // toastNotify(response.error.data.errorMessages[0], "error");
      console.log(response);
    }
    setLoading(false);
  }


  if (statesData.isLoading) {
    return <h2 className='register-page' style={{ color: 'var(--Gray-2)' }}>Data is loading...</h2>
  };


  let countryLiat: any = [{ id: 0, name: '-Select Country-' }];
  statesData.data.result.forEach((k: IState) => {
    let id = k.country.id;
    let name = k.country.name;
    if (!countryLiat.some((el: { id: number; }) => el.id === id)) {
      countryLiat.push({ id, name });
    }
  });


  return (
    <form className='d-profile' method='put' encType='multipart/form-data' onSubmit={handleSubmit} >
      <>
        {userInfo.role === SD_Roles.ADMIN ? (
          <select
            style={{ backgroundColor: `${userFullInfo.userStatus?.id === 2 ? ('var(--Red-1)') : ('var(--Green-1)')}` }}
            className="input-typ"
            name='statusId'
            value={userDto.statusId}
            disabled={!updateBtn}
            onChange={handleChange}>
            <option key={0} value={0} >--Select Status--</option>
            <option key={1} value={SD_Status.ACTIVE}>Active</option>
            <option key={2} value={SD_Status.LOCKED}>Locked</option>
            <option key={3} value={SD_Status.ARCHIVED}>Archived</option>
          </select>
        ) : (
          <input
            type="text"
            style={{ backgroundColor: `${userFullInfo.userStatus?.id === 2 ? ('var(--Red-1)') : ('var(--Green-1)')}` }}
            className='input-typ'
            name='statusId'
            value={userFullInfo.userStatus?.status}
            disabled={true}
          />
        )}

        <input
          type="text"
          style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}` }}
          className='input-typ'
          name="fullName"
          required
          value={userDto?.fullName}
          onChange={handleChange}
          disabled={!updateBtn}
        />



        <input
          type="text"
          className='input-typ'
          required
          value={userFullInfo.email}
          disabled={true}
        />
        <input
          type="text"
          style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}` }}
          className='input-typ'
          name="phoneNumber"
          required
          value={userDto?.phoneNumber}
          onChange={handleChange}
          disabled={!updateBtn}
        />
        <input
          type="text"
          style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}` }}
          className='input-typ'
          name="streetAddress"
          required
          value={userDto?.streetAddress}
          onChange={handleChange}
          disabled={!updateBtn}
        />

        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <input
            type="text"
            style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}`, width: '50%' }}
            className='input-typ'
            name="city"
            required
            value={userDto?.city}
            onChange={handleChange}
            disabled={!updateBtn}
          />
          <input
            type="text"
            style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}`, width: '50%' }}
            className='input-typ'
            name="postalCode"
            required
            value={userDto?.postalCode}
            onChange={handleChange}
            disabled={!updateBtn}
          />
        </div>

        {!updateBtn ? (
          <>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <input
                type="text"
                className='input-typ'
                style={{ width: '50%' }}
                value={userFullInfo.state?.country?.name}
                disabled={true}
              />
              <input
                type="text"
                className='input-typ'
                style={{ width: '50%' }}
                value={userFullInfo.state?.name}
                disabled={true}
              />
            </div>

          </>
        ) : (
          <>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
              <select
                style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}` }}
                className="input-typ"
                required
                // name="states.country.name"
                value={countryId}
                onChange={handleChangeCountry}>
                {countryLiat.map((c: any) => {
                  return (
                    <option key={c.id} value={c.id}> {c.name} </option>
                  );
                })}
              </select>

              <select
                className="input-typ"
                style={{ backgroundColor: `${updateBtn ? ('var(--Gray-0)') : ('var(--Gray-1)')}` }}
                name="stateId"
                required
                value={userDto?.stateId}
                onChange={handleChange}>
                <option key={0} value={0}> -Select Province- </option>
                {statesData.data.result.map((s: any) => {
                  if (s.country.id === countryId) {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    );
                  };
                  return null;
                })}
              </select>
            </div>


              {userDto.statusId != 1 ? (
                <input type="file" onChange={handleIdCardChange} style={{ marginRight: 'auto', marginBottom:'0.5rem' }} />
              ) : (null)}
          </>
        )}

        <>
          <img
            style={{ flexGrow: '1' }}
            className='profile-image'
            alt={`${userFullInfo.idCard ? userFullInfo.idCard : 'Customer Id Card image!'}`}
            src={!idCardPreview ? userFullInfo.idCard : idCardPreview}
          />
        </>
        {!updateBtn ? (
          <div style={{ width: '50%' }}>
            <p onClick={handleUpdateBtn} className="typ-item-btn btnWarning">
              Edit
            </p>
          </div>
        ) :
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <button onClick={handleCancelBtn} className="typ-item-btn btnSecondary">Cancel</button>
            <button className="typ-item-btn btnPrimary">Update</button>
          </div>
        }

      </>
    </form>
  )
}
