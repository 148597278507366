import { Link, NavLink, NavLinkProps, useLocation, useNavigate } from 'react-router-dom'
import AFXLogo from "../../images/logo/logo_1.png"
import { IUser } from '../../interfaces/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store';
import { setLoggedInUser, userState } from '../../Redux/UserAuthSlice';
import toastNotify from '../../Helper/ToastNotify';
import { useEffect, useLayoutEffect, useState } from 'react';


const Header = () => {

  const [burger, setBurger] = useState(false);
  const handleBurger = () => {
    setBurger(!burger);
  };

  // ----------------------------------------------------------//
  // // to dinamycally update title of each page
  // const location = useLocation();
  // let text = '';
  // switch (location.pathname) {
  //   case '/':
  //     text = "Home";
  //     break;
  //   case '/about':
  //     text = "About";
  //     break;
  //   case '/contact':
  //     text = "Contact Us";
  //     break;
  //   case '/transfer':
  //     text = "Transfer";
  //     break;
  //   case '/Dashboard/overview':
  //     text = "My Account";
  //       break;
  //     case '/login':
  //     text = "Log In";
  //       break;
  //     case '/register':
  //     text = "Sign Up";
  //     break;
  // }

  // text = text;

  // useLayoutEffect(() => {
  //   document.title = `${text} | Abedi-FX`;
  // }, [text]);
  // ----------------------------------------------------------//

  const dispatch = useDispatch();
  const userInfo: IUser = useSelector((state: RootState) => state.userAuthStore);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("_grecaptcha");

    dispatch(setLoggedInUser({ ...userState }));
    navigate("/");
    toastNotify("You are logged in successfully!");
    // console.log("You are logged out successfully!", "info");
  };


  return (
    <header>
      <nav id="navbar" className='navbar'>
        <img src={AFXLogo} alt="visa-cardinal-logo" />
        <div className="nav-list big-menu">
          <NavLink className={({ isActive }) => isActive ? "nav-link isActive" : "nav-link"} to="/">Home</NavLink>
          <NavLink className={({ isActive }) => isActive ? "nav-link isActive" : "nav-link"} to="/about">About Us</NavLink>
          <NavLink className={({ isActive }) => isActive ? "nav-link isActive" : "nav-link"} to="/contactus">Contact Us</NavLink>
          <NavLink className={({ isActive }) => isActive ? "nav-link isActive" : "nav-link"} to="/transfer">Transfer</NavLink>
          <NavLink className={({ isActive }) => isActive ? "nav-link isActive" : "nav-link"} to="tel:+12508162943">
            📞
            {/* <i className="bi bi-phone"></i> */}
            <span>+1 (250) 816-2943</span>
          </NavLink>
        </div>

        {userInfo.id ? (
          <div className="nav-list nav-btns">
            <Link className="nav-link big-menu nav-login" onClick={handleLogout} to="/">log out</Link>
            {/* <Link className="nav-link nav-login" to="/Dashboard/overview">My Account</Link> */}
            <NavLink
              className={({ isActive, isPending, isTransitioning }) =>
                [
                  isActive ? "nav-link isActive" : "nav-link nav-login",
                  isTransitioning ? "nav-link nav-signup" : "",
                ].join(" ")
              }
              to='/Dashboard/overview'
              style={{display:'flex', flexDirection:'column', alignItems:'center'}}
            >
              {/* <h4>Welcome</h4>
               &nbsp;
              {userInfo.fullName} */}
              My Account

            </NavLink>
          </div>

        ) : (
          <div className="nav-list nav-btns">
            <Link className="nav-link nav-login" to="/login">log in</Link>
            <Link className="nav-link nav-signup" to="/register">sign up</Link>
          </div>
        )}

        <div className={!burger ? "nav-burger-btn" : "nav-burger-btn open"} onClick={handleBurger}>
          <div id="burger" className="nav-burger"></div>
        </div>

        {burger ? (
          <div id="burger-list" className="burger-list">
            <NavLink className="burger-link" to="/" onClick={() => setBurger(!burger)} >Home</NavLink>
            <NavLink className="burger-link" to="/about" onClick={() => setBurger(!burger)} >About Us</NavLink>
            <NavLink className="burger-link" to="/contactus" onClick={() => setBurger(!burger)} >Contact Us</NavLink>
            <NavLink className="burger-link" to="/transfer" onClick={() => setBurger(!burger)} >Transfer</NavLink>

            {userInfo.id ? (
              <>
                <NavLink className="burger-link nav-login" to="/Dashboard/overview" onClick={() => setBurger(!burger)}>{ userInfo.fullName }</NavLink>
                <NavLink className="burger-link nav-signup" onClick={handleLogout} to="/" >log out</NavLink>
              </>

            ) : (
              <>
                <NavLink className="burger-link" to="/login" onClick={() => setBurger(!burger)} >log in</NavLink>
                <NavLink className="burger-link" to="/register" onClick={() => setBurger(!burger)} >sign up</NavLink>
              </>
            )}

            {"admin" === "admin" ? (
              <>
                {/* <Link className="burger-link" onClick={() => setBurger(!burger)}  to={`/categories`} >Categories</Link>
                <Link className="burger-link" onClick={() => setBurger(!burger)}  to={`/users`} >Users</Link>
                <Link className="burger-link" onClick={() => setBurger(!burger)}  to={`/register`}>Register</Link> */}
              </>
            ) : null}

          </div>
        ) : null}

      </nav>
    </header>
  )
}

export default Header
