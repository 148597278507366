import React, { useState } from 'react';
import { Recaptcha } from '../MainLayout/Recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';
import loadingGif from "../../images/gifs/addLoadingGif.gif";
import { useNavigate } from 'react-router-dom';
import { IReceiverNew} from '../../interfaces/IReceiverNew';
import { IUser } from '../../interfaces/IUser';
import { useCreateReceiverMutation } from '../../Api/ReceiversApi';
import toastNotify from '../../Helper/ToastNotify';
import IApiResponse from '../../interfaces/IApiResponse';


interface Props {
  verified: boolean
  setVerified: React.Dispatch<React.SetStateAction<boolean>>
  captchaRef: React.RefObject<ReCAPTCHA>
  userInfo: IUser
  states: any
  countryLiat: any
  banks: any
}


export const ReceiverAdd = ({ verified, setVerified, captchaRef, userInfo, states, countryLiat, banks }: Props) => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [countryId, setCountryId] = useState(0);

  const handleChangeCountry = (evt: React.ChangeEvent<HTMLSelectElement>) => {
    setCountryId(parseInt(evt.target.value));
    setNewReceiver(() => {
      return { ...newReceiver, stateId: 0 }
    });
  }


  const [newReceiver, setNewReceiver] = useState<IReceiverNew>(() => {
    return {
      appUserId: userInfo.id,
      fullName: '',
      phoneNumber: '',
      email: '',
      streetAddress: '',
      city: '',
      postalCode: '',
      stateId: 0,
      bankId: 0,

      bankAccountNo: '',
      bankInstitutionNo: '',
      transitNo: '',

      iRCardNo: '',
      iRAccountNo: '',
      iRIBAN: '',
    }
  });


  const handleChange = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement & HTMLTextAreaElement>) => {
    const target = evt.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setNewReceiver(() => {
      return { ...newReceiver, [name]: value }
    });
  };


  const [createNewReceiver] = useCreateReceiverMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);



    // returning error if fields are not provided
    // for (const [key, value] of Object.entries(newReceiver)) {
    //   if (value === '' || value == 0) {
    //     let text;
    //     switch (key.toString()) {
    //       case 'fullName':
    //         text = "Full Name";
    //         break;
    //       case 'phoneNumber':
    //         text = "Phone Number";
    //         break;
    //       case 'email':
    //         text = "Email";
    //         break;
    //       case 'streetAddress':
    //         text = "Street Address";
    //         break;
    //       case 'city':
    //         text = "City";
    //         break;
    //       case 'Postal Code':
    //         text = "Phone Number";
    //         break;
    //       case 'stateId':
    //         text = "Province";
    //         break;
    //       case 'bankId':
    //         text = "Bank";
    //         break;
    //     }
    //     toastNotify(`Please enter ${text}.`, "error");
    //     // console.log(`${key}`);
    //     setLoading(false);
    //     return;
    //   }
    //   // console.log(`${key}: ${value}`);
    // }



    // appends data from the form and local variables to the FromData
    const formData = new FormData();
    formData.append("appUserId", newReceiver?.appUserId !== undefined ? newReceiver?.appUserId.toString() : '');
    formData.append("fullName", newReceiver?.fullName !== undefined ? newReceiver?.fullName.toString() : '');
    formData.append("phoneNumber", newReceiver?.phoneNumber !== undefined ? newReceiver?.phoneNumber.toString() : '');
    formData.append("email", newReceiver?.email !== undefined ? newReceiver?.email.toString() : '');
    formData.append("streetAddress", newReceiver?.streetAddress !== undefined ? newReceiver?.streetAddress.toString() : '');
    formData.append("city", newReceiver?.city !== undefined ? newReceiver?.city.toString() : '');
    formData.append("postalCode", newReceiver?.postalCode !== undefined ? newReceiver?.postalCode.toString() : '');
    formData.append("stateId", newReceiver?.stateId !== undefined ? newReceiver?.stateId.toString() : '');
    formData.append("bankId", newReceiver?.bankId !== undefined ? newReceiver?.bankId.toString() : '');
    if (countryId != 97) {
      formData.append("bankAccountNo", newReceiver?.bankAccountNo !== undefined ? newReceiver?.bankAccountNo.toString() : '');
      formData.append("bankInstitutionNo", newReceiver?.bankInstitutionNo !== undefined ? newReceiver?.bankInstitutionNo.toString() : '');
      formData.append("transitNo", newReceiver?.transitNo !== undefined ? newReceiver?.transitNo.toString() : '');
    } else {
      formData.append("iRCardNo", newReceiver?.iRCardNo !== undefined ? newReceiver?.iRCardNo.toString() : '');
      formData.append("iRAccountNo", newReceiver?.iRAccountNo !== undefined ? newReceiver?.iRAccountNo.toString() : '');
      formData.append("iRIBAN", newReceiver?.iRIBAN !== undefined ? newReceiver?.iRIBAN.toString() : '');
    }


    // submits the data for CategoryApi
    const response: IApiResponse = await createNewReceiver(formData);
    // const response = await createCategory(newCategory);
    if (response.data?.isSuccess) {

      // so hard to find out how to use loop and setState()
      // resting newReceiver and cleaning the form here
      for (const [key, value] of Object.entries(newReceiver)) {
        setNewReceiver(prev => ({ ...prev, [key]: undefined, appUserId: userInfo.id }));
      }
      setCountryId(0);


      localStorage.removeItem("_grecaptcha");

      if (captchaRef.current !== null) {
        captchaRef.current.reset();
      }
      setVerified(false);


      setLoading(false);
      // console.log(response)
      toastNotify("New Price added successfully!");
    } else {
      console.log(response)
      // toastNotify(response.error.data.errorMessages[0], "error");
    }
    setLoading(false);
    return;

  }


  return (
    <form method="post" className='receiver-form' onSubmit={handleSubmit}>
      <h3 className="list-title" style={{ marginBottom: "1rem", borderBottom:'1px solid var(--Gray-6)' }}>Receiver Information form</h3>
      <div className='receiver-info'>
        <div style={{ width: '100%' }}>
          <div className='input-typ-box'>
            <input
              type="text"
              name='fullName'
              className="input-typ"
              // required
              value={newReceiver.fullName === undefined ? '' : newReceiver.fullName}
              onChange={handleChange}
            />
            <label
              htmlFor="fullName"
              className='lbl-input-typ'
            >
              Full Name
            </label>
          </div>
          <div className='input-typ-box'>
            <input
              type="text"
              name='phoneNumber'
              className="input-typ"
              required
              value={newReceiver.phoneNumber === undefined ? '' : newReceiver.phoneNumber}
              onChange={handleChange}
            />
            <label
              htmlFor="phoneNumber"
              className='lbl-input-typ'
            >
              Phone Number
            </label>
          </div>

          <div className='input-typ-box'>
            <input
              type="email"
              name='email'
              className="input-typ"
              required
              value={newReceiver.email === undefined ? '' : newReceiver.email}
              onChange={handleChange}
            />
            <label
              htmlFor="email"
              className='lbl-input-typ'
            >
              Email Adrress
            </label>
          </div>
          <div className='input-typ-box'>
            <input
              type="text"
              name='streetAddress'
              className="input-typ"
              required
              value={newReceiver.streetAddress === undefined ? '' : newReceiver.streetAddress}
              onChange={handleChange}
            />
            <label
              htmlFor="streetAddress"
              className='lbl-input-typ'
            >
              Street Address
            </label>
          </div>
          <div style={{ display: 'flex', gap: '0.5rem', marginBottom: '1rem' }}>
            <div className='input-typ-box'>
              <input
                type="text"
                name='city'
                className="input-typ"
                required
                value={newReceiver.city === undefined ? '' : newReceiver.city}
                onChange={handleChange}
              />
              <label
                htmlFor="city"
                className='lbl-input-typ'
              >
                City
              </label>
            </div>
            <div className='input-typ-box'>
              <input
                type="text"
                name='postalCode'
                className="input-typ"
                required
                value={newReceiver.postalCode === undefined ? '' : newReceiver.postalCode}
                onChange={handleChange}
              />
              <label
                htmlFor="postalCode"
                className='lbl-input-typ'
              >
                Postal Code
              </label>
            </div>
          </div>

          <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
            <div className='input-typ-box'>
              <select
                className="input-typ"
                style={{ marginBottom: '0' }}
                // name="countryId"
                value={countryId}
                onChange={handleChangeCountry}
              >
                {countryLiat.map((c: any) => {
                  return (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
              </select>
              <label
                htmlFor="postalCode"
                className='lbl-input-typ'
              >
                Select Country
              </label>
            </div>

            <div className='input-typ-box'>
              <select
                className="input-typ"
                style={{ marginBottom: '0' }}
                name="stateId"
                required
                value={newReceiver.stateId === undefined ? '' : newReceiver.stateId}
                onChange={handleChange}
              >
                {/* <option key={-1} value={0} disabled selected>--Select Province--</option> */}
                <option key={0} value={0}> </option>
                {states.map((s: any) => {
                  if (s.country.id === countryId) {
                    return (
                      <option key={s.id} value={s.id}>
                        {s.name}
                      </option>
                    );
                  };
                  return null;
                })}
              </select>
              <label
                htmlFor="postalCode"
                className='lbl-input-typ'
              >
                Select Province
              </label>
            </div>
          </div>
        </div>

        <div className='input-typ-box'>
          <div className='input-typ-box'>
            <select
              className="input-typ"
              name="bankId"
              required
              value={newReceiver.bankId === undefined ? '' : newReceiver.bankId}
              onChange={handleChange}
            >
              {/* <option key={-1} value={0} disabled selected>--Select Province--</option> */}
              <option key={0} value={0}>  </option>
              {banks.map((s: any) => {
                if (s.country.id === countryId) {
                  return (
                    <option key={s.id} value={s.id}>
                      {s.name}
                    </option>
                  );
                };
                return null;
              })}
            </select>
            <label
              htmlFor="bankId"
              className='lbl-input-typ'
            >
              Select bank
            </label>
          </div>


          {countryId != 97 ? (
            <>
              <div className='input-typ-box'>
                <input
                  type="text"
                  name='bankAccountNo'
                  className="input-typ"
                  required
                  maxLength={12}
                  value={newReceiver.bankAccountNo === undefined ? '' : newReceiver.bankAccountNo}
                  onChange={handleChange}
                />
                <label
                  htmlFor="bankAccountNo"
                  className='lbl-input-typ'
                >
                  Bank Account No (7-12 Digits)
                </label>
              </div>

              <div className='input-typ-box'>
                <input
                  type="text"
                  name='bankInstitutionNo'
                  className="input-typ"
                  required
                  maxLength={3}
                  value={newReceiver.bankInstitutionNo === undefined ? '' : newReceiver.bankInstitutionNo}
                  onChange={handleChange}
                />
                <label
                  htmlFor="bankInstitutionNo"
                  className='lbl-input-typ'
                >
                  Bank Institution No (3 Digits)
                </label>
              </div>

              <div className='input-typ-box'>
                <input
                  type="text"
                  name='transitNo'
                  className="input-typ"
                  required
                  maxLength={5}
                  value={newReceiver.transitNo === undefined ? '' : newReceiver.transitNo}
                  onChange={handleChange}
                />
                <label
                  htmlFor="transitNo"
                  className='lbl-input-typ'
                >
                  Bank Transit No (5 Digits)
                </label>
              </div>
            </>
          ) : (
            <>
              <div className='input-typ-box'>
                <input
                  type="text"
                  name='iRCardNo'
                  className="input-typ"
                  required
                  value={newReceiver.iRCardNo === undefined ? '' : newReceiver.iRCardNo}
                  onChange={handleChange}
                />
                <label
                  htmlFor="iRCardNo"
                  className='lbl-input-typ'
                >
                  Card No
                </label>
              </div>

              <div className='input-typ-box'>
                <input
                  type="text"
                  name='iRAccountNo'
                  className="input-typ"
                  required
                  value={newReceiver.iRAccountNo === undefined ? '' : newReceiver.iRAccountNo}
                  onChange={handleChange}
                />
                <label
                  htmlFor="iRAccountNo"
                  className='lbl-input-typ'
                >
                  Bank Account No
                </label>
              </div>

              <div className='input-typ-box'>
                <input
                  type="text"
                  name='iRIBAN'
                  className="input-typ"
                  required
                  value={newReceiver.iRIBAN === undefined ? '' : newReceiver.iRIBAN}
                  onChange={handleChange}
                />
                <label
                  htmlFor="iRIBAN"
                  className='lbl-input-typ'
                >
                  IBAN No (شبا)
                </label>
              </div>
            </>
          )}


          <Recaptcha setVerified={setVerified} captchaRef={captchaRef} />

          {loading ? (
            <img src={loadingGif} alt='loading gif' className="list-btn" style={{ marginRight: "0" }} />
          ) : (
            <div className='typ-item-btnBox' style={{ justifyContent: 'center', gap: '1rem' }}>
              {/* <button onClick={() => navigate(-1)} className="typ-item-btn btnDangerB"> Back </button> */}

              {verified ? (
                <button className="typ-item-btn btnPrimary" style={{ flexGrow: "1" }}> Submit </button>
              ) : (
                <button className="typ-item-btn btnSecondary" disabled style={{ flexGrow: "1" }}> Submit </button>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  )
}
