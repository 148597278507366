import { useState } from "react";


interface Props {
    receiver: any,
    setReceiverId: React.Dispatch<React.SetStateAction<number>>
}

export const ReceiversListRow = ({ receiver, setReceiverId }: Props) => {

    const [updateBtn, setUpdateBtn] = useState(false);
    const handleUpdateBtn = () => {
        setUpdateBtn(!updateBtn);
    };

    const handleCancelBtn = () => {
    setUpdateBtn(!updateBtn);
  };

    // const [deletPrice] = useDeletePriceMutation();
    // const handleDelete = async () => {
    //     setLoading(true);

    //     // submits the data for CategoryApi
    //     const response: IApiResponse = await deletPrice(newPrice.id);
    //     if (response.data?.isSuccess) {
    //         handleUpdateBtn();
    //         setLoading(false);
    //         toastNotify("Price Deleted successfully!");
    //     } else {
    //         toastNotify(response.error.data.errorMessages[0], "error");
    //     }
    //     setLoading(false);
    // }



    return (
        <div className="d-receiver-list-row">
            <input
                type="text"
                className='input-typ'
                style={{margin:'0', border: '0'}}
                name='fullName'
                value={receiver.fullName}
                disabled={!updateBtn}
            />
            <input
                type="text"
                className='input-typ'
                style={{margin:'0', border: '0'}}
                name='phoneNumber'
                value={receiver.phoneNumber}
                disabled={!updateBtn}
            />
            <input
                type="text"
                className='input-typ'
                style={{margin:'0', border: '0'}}
                name='email'
                value={receiver.email}
                disabled={!updateBtn}
            />
            <input
                type="text"
                className='input-typ'
                style={{margin:'0', border: '0'}}
                name='email'
                value={receiver.bank.name}
                disabled={!updateBtn}
            />
            <p onClick={() => setReceiverId(receiver.id)} className="typ-item-btn btnPrimary" style={{margin:'0', padding:'9px', width:'100%'}}>
                Send Money
            </p>

        </div>
    )
}
