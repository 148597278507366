import { useGetUserByIdQuery } from '../Api/AuthApi';
import { useGetPricesQuery } from '../Api/PriceApi';
import { SD_Roles } from '../Utility/StaticDetails';
import { PriceListAdmin } from '../components/AdminOverview/PriceListAdmin';
import PriceAdd from '../components/AdminOverview/PriceAdd';
import { UserProfile } from '../components/Users/UserProfile';
import { useOutletContext } from 'react-router-dom';

export const DashboardOverview = () => {

    const [userInfo, statesData]: any = useOutletContext();

    const userFullInfo = useGetUserByIdQuery(userInfo.id);

    const { data, isLoading } = useGetPricesQuery(null);

    if (isLoading || userFullInfo.isLoading || statesData.isLoading) {
        return <h4>Data is loading...</h4>
    };

    return (
        <div className="d-overview">
            {userInfo.role === SD_Roles.ADMIN ? (
                <>
                    <PriceListAdmin prices={data.result} />
                    <PriceAdd states={statesData.data.result} />
                </>
            ) : (null)}

            {userInfo.role === SD_Roles.CUSTOMER ? (
                <>
                    <UserProfile userFullInfo={userFullInfo.data.result} />
                </>
            ) : (null)}
        </div>
    )
}
