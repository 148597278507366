import ReCAPTCHA from 'react-google-recaptcha';
import { useGetReceiversQuery } from '../Api/ReceiversApi';
import { useOutletContext } from 'react-router-dom';
import { IState } from '../interfaces/IState';
import { useGetBanksQuery } from '../Api/BankApi';
import { ReceiverAdd } from '../components/Transfer/ReceiverAdd';
import { TransferRequestForm } from '../components/Transfer/TransferRequestForm';
import { useState } from 'react';
import { TransferRequestList } from '../components/Transfer/TransferRequestList';
import { useGetTransfersQuery } from '../Api/TransferApi';
import { TransferRequestDetail } from '../components/Transfer/TransferRequestDetail';
import { ReceiversList } from '../components/Transfer/ReceiversList';

interface Props {
  verified: boolean
  setVerified: React.Dispatch<React.SetStateAction<boolean>>
  captchaRef: React.RefObject<ReCAPTCHA>
}

export const Transfers = ({ verified, setVerified, captchaRef }: Props) => {
  const [userInfo, statesData]: any = useOutletContext();

  const [receiverId, setReceiverId] = useState(0);
  const [transferRequestId, setTransferRequestId] = useState(0);


  const receivers = useGetReceiversQuery(userInfo.id.toString());
  const TransferRequests = useGetTransfersQuery(userInfo.id.toString());

  const banksFromDb = useGetBanksQuery(null);

  if (receivers.isLoading || TransferRequests.isLoading || statesData.isLoading || banksFromDb.isLoading) {
    return <h2 className='register-page' style={{ color: 'var(--Gray-2)' }}>Data is loading...</h2>
  };


  let countryLiat: any = [{ id: 0, name: '' }];
  statesData.data.result.forEach((k: IState) => {
    let id = k.country.id;
    let name = k.country.name;
    if (!countryLiat.some((el: { id: number; }) => el.id === id)) {
      countryLiat.push({ id, name });
    }
  });

  return (
    <div className="d-receiver">
      {
        (() => {
          if (receiverId === 0 && transferRequestId === 0) {
            return (
              <>
                <ReceiverAdd
                  verified={verified}
                  setVerified={setVerified}
                  captchaRef={captchaRef}
                  userInfo={userInfo}
                  states={statesData.data.result}
                  countryLiat={countryLiat}
                  banks={banksFromDb.data.result}
                />

                <ReceiversList
                  receivers={receivers.data.result}
                  setReceiverId={setReceiverId}
                />
                <TransferRequestList
                  transferRequests={TransferRequests.data.result}
                  setTransferRequestId={setTransferRequestId}
                />
              </>
            )
          } else if (receiverId > 0) {
            return (
              <TransferRequestForm
                receiverId={receiverId}
                setReceiverId={setReceiverId}
                verified={verified}
                setVerified={setVerified}
                captchaRef={captchaRef}
              />
            )
          } else if (transferRequestId > 0) {
            return (
              <TransferRequestDetail
                userInfo={userInfo}
                transferRequestId={transferRequestId}
                setTransferRequestId={setTransferRequestId}
                verified={verified}
                setVerified={setVerified}
                captchaRef={captchaRef}
              />
            )
          }
        })()
      }
    </div>
  )
}
