import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const bankApi = createApi({
    reducerPath: "bankApi",
    baseQuery: fetchBaseQuery({
        // baseUrl:"http://localhost:5058/api/"
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers: Headers, api) => {
            const token = localStorage.getItem("token");
            token && headers.append("Authorization", `Bearer ${token}`);
        }
    }),
    tagTypes: ["Banks"],

    endpoints: (builder) => ({
        getBanks: builder.query({
            query: () => ({
                url:"Banks"
            }),
            providesTags:["Banks"]
        }),

        getBankById: builder.query({
            query: (id) => ({
                url:`Banks/${id}`
            }),
            providesTags:["Banks"]
        }),

        createBank: builder.mutation({
            query: (data) => ({
                url: "Banks",
                method: "POST",
                body: data
            }),
            invalidatesTags:["Banks"]
        }),

        updateBank: builder.mutation({
            query: ({data,id}) => ({
                url: `Banks/${id}`,
                method: "PUT",
                body: data
            })
        }),

        deleteBank: builder.mutation({
            query: (id) => ({
                url: `Banks/${id}`,
                method: "DELETE"
            }),
            invalidatesTags:["Banks"]
        }),

    }),
});


export const {
    useGetBanksQuery,
    useGetBankByIdQuery,
    useCreateBankMutation,
    useDeleteBankMutation,
    useUpdateBankMutation} = bankApi;
export default bankApi;