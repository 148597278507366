import ReCAPTCHA from "react-google-recaptcha"
import { ITransferReq } from "../../interfaces/ITransferReq"
import { useState } from "react";
import { SD_TransferStatus } from "../../Utility/StaticDetails";

interface Props {
    transferRequests: ITransferReq[],
    setTransferRequestId: React.Dispatch<React.SetStateAction<number>>
}

export const TransferRequestList = ({ transferRequests, setTransferRequestId }: Props) => {

    // storing the filter value from select element
    const [filterString, setFilterString] = useState('');

    // empty array to push Requests that contain filterString
    let filteredRequests: ITransferReq[] = [];

    // search the data to find and push matched ones with filterString
    for (const [key, value] of Object.entries(transferRequests)) {
        if (value.transferStatus.toLowerCase().includes(filterString)) {
            filteredRequests.push(value);
        }
    };




    // gets the string from input field
    const [searchString, setSearchString] = useState('');

    // empty array to push Requests that contain searchString
    let searchedRequests: ITransferReq[] = [];

    const handleChangeSearchString = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(() => {
            return (evt.target.value).toLowerCase();
        });
    }

    // checks to see receiver has the searchString or not, if yes then pushes the receiver to the filteredreceivers
    for (const [key, value] of Object.entries(filteredRequests)) {
        if (value.receiver.appUser.fullName?.toLowerCase().includes(searchString) ||
            value.receiver.appUser.phoneNumber?.toLowerCase().includes(searchString) ||
            value.receiver.fullName?.toLowerCase().includes(searchString) ||
            value.receiver.phoneNumber?.toLowerCase().includes(searchString) ||
            value.transferStatus.toLowerCase().includes(searchString)) {
            searchedRequests.push(value);
        }
    };


    return (
        <div className='transfer-requests'>
            <h3 className="list-title" style={{ margin: "0.5rem 0 0.5rem 0", borderBottom: '1px solid var(--Gray-6)' }}>Money transfer history</h3>

            {/* //----- searcg bar -----// */}
            <div style={{ display: 'flex', gap: '0.5rem', width: '100%' }}>
                <select
                    className="input-typ"
                    name="transferStatus"
                    style={{ width: '20rem' }}
                    onChange={(evt) => setFilterString(evt.target.value.toLowerCase())}
                >
                    <option key={0} value={''} >All</option>
                    <option key={1} value={SD_TransferStatus.PENDING} >PENDING</option>
                    <option key={2} value={SD_TransferStatus.APPROVED} >APPROVED</option>
                    <option key={3} value={SD_TransferStatus.REJECTED} >REJECTED</option>
                    <option key={4} value={SD_TransferStatus.COMPLETED} >COMPLETED</option>
                </select>

                <input
                    type="text"
                    className='input-typ'
                    style={{ width: '100%' }}
                    value={searchString}
                    onChange={handleChangeSearchString}
                    placeholder="Search by Name or Phone number"
                />
            </div>

            {searchedRequests.length > 0 ? (
                searchedRequests.map((tr: ITransferReq) => {
                    return (
                        <div key={tr.id} className="requests-row">
                            <div className='requests-typ-box'>
                                <p>{tr.receiver.appUser.fullName}</p>
                                <p>{tr.receiver.appUser.phoneNumber}</p>
                                <label
                                    htmlFor="fullName"
                                    className='lbl-input-typ'
                                    style={{ top: '-0.8rem', left: '0.2rem', backgroundColor: 'transparent' }}
                                >
                                    Sender's Info
                                </label>
                            </div>

                            <div className='requests-typ-box'>
                                <p>{tr.receiver.fullName}</p>
                                <p>{tr.receiver.phoneNumber}</p>
                                <label
                                    htmlFor="fullName"
                                    className='lbl-input-typ'
                                    style={{ top: '-0.8rem', left: '0.2rem', backgroundColor: 'transparent' }}
                                >
                                    Receiver's Info
                                </label>
                            </div>

                            {/* <div className='requests-typ-box'>
                                <p>Created: {tr.createDate}</p>
                                <p>Update: {tr.updatedDate === tr.createDate ? 'Not updated' : tr.updatedDate }</p>
                                <label
                                    htmlFor="fullName"
                                    className='lbl-input-typ'
                                    style={{ top: '-0.8rem', left: '0.2rem', backgroundColor: 'transparent' }}
                                >
                                    Date
                                </label>
                            </div> */}

                            <div className='requests-typ-box'>
                                {(() => {
                                    if (tr.transferStatus === 'pending') {
                                        return (
                                            <h4 className="transfer-status"
                                                onClick={() => setTransferRequestId(tr.id)}
                                                style={{ color: 'var(--Orange-6)' }}
                                            >
                                                {tr.transferStatus?.toUpperCase()}
                                                &nbsp;
                                                <i className="bi bi-clock-history"></i>
                                            </h4>
                                        )
                                    } else if (tr.transferStatus === 'approved') {
                                        return (
                                            <h4
                                                className="transfer-status"
                                                onClick={() => setTransferRequestId(tr.id)}
                                                style={{ color: 'var(--Blue-7)'}}
                                            >
                                                {tr.transferStatus?.toUpperCase()}
                                                &nbsp;
                                                <i className="bi bi-hand-thumbs-up"></i>
                                            </h4>
                                        )
                                    } else if (tr.transferStatus === 'completed') {
                                        return (
                                            <h4
                                                className="transfer-status"
                                                onClick={() => setTransferRequestId(tr.id)}
                                                style={{ color: 'var(--Green-7)' }}
                                            >
                                                {tr.transferStatus?.toUpperCase()}
                                                &nbsp;
                                                <i className="bi bi-check-circle"></i>
                                            </h4>
                                        )
                                    } else if (tr.transferStatus === 'rejected') {
                                        return (
                                            <h4
                                                className="transfer-status"
                                                onClick={() => setTransferRequestId(tr.id)}
                                                style={{ color: 'var(--Red-7)' }}
                                            >
                                                {tr.transferStatus?.toUpperCase()}
                                                &nbsp;
                                                <i className="bi bi-x-circle"></i>
                                            </h4>
                                        )
                                    }
                                })()}

                                <label
                                    htmlFor="fullName"
                                    className='lbl-input-typ'
                                    style={{ top: '-0.8rem', left: '0.2rem', backgroundColor: 'transparent' }}
                                >
                                    Transfer Status
                                </label>
                            </div>
                        </div>
                    )
                })
            ) : (
                <h3>No records to show.</h3>
            )}

        </div>
    );
};
