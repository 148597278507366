import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const priceApi = createApi({
    reducerPath: "priceApi",
    baseQuery: fetchBaseQuery({
        // baseUrl:"http://localhost:5058/api/"
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers: Headers, api) => {
            const token = localStorage.getItem("token");
            // token && headers.append("Authorization", `Bearer ${token}`);
            if (token) {
                headers.append("Authorization", `Bearer ${token}`);
            }
        }
    }),
    tagTypes: ["Prices"],

    endpoints: (builder) => ({
        getPrices: builder.query({
            query: () => ({
                url:"Prices"
            }),
            providesTags:["Prices"]
        }),

        getPriceById: builder.query({
            query: (id) => ({
                url:`Prices${id}`
            }),
            providesTags:["Prices"]
        }),

        createPrice: builder.mutation({
            query: (data) => ({
                url: "Prices",
                method: "POST",
                body: data
            }),
            invalidatesTags:["Prices"]
        }),

        updatePrice: builder.mutation({
            query: ({data,id}) => ({
                url: `Prices/${id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags:["Prices"]
        }),

        deletePrice: builder.mutation({
            query: (id) => ({
                url: `Prices/${id}`,
                method: "DELETE"
            }),
            invalidatesTags:["Prices"]
        }),

    }),
});


export const {
    useGetPricesQuery,
    useGetPriceByIdQuery,
    useCreatePriceMutation,
    useDeletePriceMutation,
    useUpdatePriceMutation} = priceApi;
export default priceApi;