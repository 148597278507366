import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    transfer: [],
};

export const transferSlice = createSlice({
    name: 'transfer',
    initialState: initialState,
    reducers: {
        setTransfer: (state, action) => {
            state.transfer= action.payload;
        },
    },
});


export const { setTransfer } = transferSlice.actions;
export const transferReducer = transferSlice.reducer;