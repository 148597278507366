import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const About = () => {
  const TITLE = 'About | Abedi-FX';
  return (
    <HelmetProvider>

      <div>
        <Helmet>
          <title>{TITLE}</title>
        </Helmet>
      </div>
    </HelmetProvider>
  )
}
