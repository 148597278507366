import { Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import Header from "../components/MainLayout/Header";
import { Register } from "./Register";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { setLoggedInUser } from "../Redux/UserAuthSlice";
import { IUser } from "../interfaces/IUser";

import { jwtDecode } from "jwt-decode";
import { Login } from "./Login";
import {Dashboard} from "./Dashboard";
import { Footer } from "../components/MainLayout/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import { NotFoundPage } from "../auth/NotFoundPage";
import { Users } from "./Users";
import { DashboardOverview } from "./DashboardOverview";
import { Transfers } from "./Transfers";
import { About } from "./About";
import { ContactUs } from "./ContactUs";

function Main() {

  const dispatch = useDispatch();
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      const { id, userId, fullName, email, role }: IUser = jwtDecode(localToken);
      dispatch(setLoggedInUser({ id, userId, fullName, email, role }));
    }
  },[])


  // defining a variablle for ReCAPTCHA
  const captchaRef = useRef<ReCAPTCHA>(null);

  // defining defining a variablle to sitekey is verified or not
  const [verified, setVerified] = useState(() => {
    return false;
  });


  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/login" element={<Login verified={verified} setVerified={setVerified} captchaRef={captchaRef}  />} />
        <Route path="/register" element={<Register verified={verified} setVerified={setVerified} captchaRef={captchaRef} />} />
        <Route path="/dashboard" element={<Dashboard />} >
          <Route path="overview" element={<DashboardOverview />} />
          <Route path="users" element={<Users />} />
          <Route path="transfers" element={<Transfers verified={verified} setVerified={setVerified} captchaRef={captchaRef} />} />
        </Route>
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
      <Footer />
    </>
  );
}

export default Main;
