import { useGetUsersQuery } from '../Api/AuthApi';
import { IUser } from '../interfaces/IUser';
import { UserProfile } from '../components/Users/UserProfile';
import { useOutletContext } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const Users = () => {
    const TITLE = 'Users | Abedi-FX';

    const userInfo: IUser = useOutletContext()
    const allUsers = useGetUsersQuery(null);

    if (allUsers.isLoading) {
        return <h4>Data is loading...</h4>
    };
    return (
        <HelmetProvider>

            <div className="d-user">
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                {allUsers.data.result.map((user: IUser) => (
                    <UserProfile key={user.userId} userFullInfo={user} />
                ))}
            </div>
        </HelmetProvider>
    )
}
