import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const receiverApi = createApi({
    reducerPath: "receiverApi",
    baseQuery: fetchBaseQuery({
        // baseUrl:"http://localhost:5058/api/"
        baseUrl: process.env.REACT_APP_API_URL,
        prepareHeaders: (headers: Headers, api) => {
            const token = localStorage.getItem("token");
            // token && headers.append("Authorization", `Bearer ${token}`);
            if (token) {
                headers.append("Authorization", `Bearer ${token}`);
            }
        }
    }),

    tagTypes: ["Receivers"],
    endpoints: (builder) => ({
        getReceivers: builder.query({
            query: (id) => ({
                url: "Receivers",
                params: {
                    id:id,
                },
            }),
            providesTags:["Receivers"]
        }),

        getReceiverById: builder.query({
            query: (id) => ({
                url:`Receivers/${id}`
            }),
            providesTags:["Receivers"]
        }),

        createReceiver: builder.mutation({
            query: (data) => ({
                url: "Receivers",
                method: "POST",
                body: data
            }),
            invalidatesTags:["Receivers"]
        }),

        updateReceiver: builder.mutation({
            query: ({data,id}) => ({
                url: `Receivers/${id}`,
                method: "PUT",
                body: data
            }),
            invalidatesTags:["Receivers"]
        }),

        deleteReceiver: builder.mutation({
            query: (id) => ({
                url: `Receivers/${id}`,
                method: "DELETE"
            }),
            invalidatesTags:["Receivers"]
        }),

    }),
});


export const {
    useGetReceiversQuery,
    useGetReceiverByIdQuery,
    useCreateReceiverMutation,
    useDeleteReceiverMutation,
    useUpdateReceiverMutation} = receiverApi;
export default receiverApi;