import { useState } from 'react';
import { IPrice } from '../../interfaces/IPrice';
import { PriceListRowAdmin } from './PriceListRowAdmin';

interface Props {
    prices: IPrice[]
}

export const PriceListAdmin = ({ prices }: Props) => {

    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = '0' + today.getDate();
    // const hour = today.getHours();
    // const minute = today.getMinutes();
    // const second = today.getSeconds();
    // const ampm = (hour >= 12) ? "PM" : "AM";
    // const currentDate = `${year}-${month}-${date}, ${hour}:${minute}:${second} ${ampm}`;
    const currentDate = `${year}-${month}-${date}`;

    // storing the filter value from select element
    const [filterDate, setFilterDate] = useState('');

    // empty array to push Requests that contain filterString
    let filteredPrices: IPrice[] = [];

    // const handeFilterDate = (evt: React.ChangeEvent<HTMLSelectElement & HTMLInputElement & HTMLTextAreaElement>) => {
    //     setFilterDate(() => {
    //         return (evt.target.value).toLowerCase();
    //     });
    //     // search the data to find and push matched ones with filterString
    //     for (const [key, value] of Object.entries(prices)) {
    //         let date = value.updatedDate.toString().split(' ')[0];
    //         if (date === filterDate) {
    //             filteredPrices.push(value);
    //         }
    //     };
    // }

    // search the data to find and push matched ones with filterString
    for (const [key, value] of Object.entries(prices)) {
        let date = value.updatedDate.toString().split(' ')[0];
        if (date === filterDate) {
            filteredPrices.push(value);
        }
    };

    return (
        <div className='admin-price'>

            {/* //----- list title -----// */}
            <h3 className="list-title" style={{ marginBottom: "1rem", borderBottom: '1px solid var(--Gray-6)' }}>Currency Price History</h3>

            {/* //----- date filter -----// */}
            <input
                className='input-typ'
                style={{ fontSize: '16px', width: '10rem', borderRadius: '10px' }}
                type='date'
                onChange={(evt) => setFilterDate(evt.target.value.toLowerCase())}
            />

            <div className='admin-price-title'>
                <p>Currency</p>
                <p>Country</p>
                <p>We Buy</p>
                <p>We Sell</p>
                <p>Last Update</p>
                <p>Actions</p>
            </div>
            {filterDate ? (
                filteredPrices.map((price: IPrice) => {
                    return (
                        <PriceListRowAdmin key={price.id} price={price} />
                    )
                })
            ) : (
                prices.map((price: IPrice) => {
                    return (
                        <PriceListRowAdmin key={price.id} price={price} />
                    )
                })
            )}

        </div>
    )
}
